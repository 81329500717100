<mat-dialog-content class="mat-typography typo">
<mat-card class="card">
  <h1 class="h1">Change Status.</h1>
  <p class="p">Are you sure to change the status ?</p>

</mat-card>
</mat-dialog-content>
<div fxLayout="row" fxLayoutAlign="end end">
  <mat-dialog-actions>
    <button mat-button mat-dialog-close  class="cbtn">Cancel</button>
    <button  [mat-dialog-close]="true" cdkFocusInitial (click)="toggle()" class="btn">
        Proceed
    </button>
  </mat-dialog-actions>
</div>
