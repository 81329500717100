<div class="router" *ngFor="let detail of details">

  <div class="btn_div">
    <button class="btn_back" (click)="navigate()"><span><mat-icon class="back_icon">reply</mat-icon></span>Back</button>
  </div>
  <button class="green last" *ngIf="(detail.active===1)" (click)="openDialog(detail.id)">Active</button>
  <button class="red last" *ngIf="(detail.active===0)" (click)="openDialogg(detail.id)">Inctive</button>
<section class="intro_sec" fxLayout="row">
  <div> <img src="{{detail.cmp_logo}}" alt="" class="logo mr1"></div>

  <div fxLayout="column">
    <p class="name_p">{{detail.cmp_name}}</p>
    <p  class="intro_p">Address:{{detail.city}},{{detail.state}}</p>
   <div fxLayout="row">
  <span class="intro_span"><i class="fas fa-circle"></i></span><span class="intro_span">EMAIL : | Website : {{detail.cmp_website}}</span><span class="intro_span">PAN\TAN : {{detail.companypan}}</span>
   </div>
   <div fxLayout="row" class="badge_div" >
     <p *ngFor="let pt of detail.pitchTags"  [ngStyle]="{'background-color': pt.bgcolor , 'color':pt.color }" class="tag_s">{{pt.name}}</p>
   </div>
   <div fxLayout="row">
    <span (click)="goTo(detail.facebookurl)" class="mt1"><img src="assets/facebook.png" alt="" class="sec_img"></span>
    <span (click)="goTo(detail.instagramurl)" class="mt1"><img src="assets/instagram.png" alt="" class="sec_img"></span>
    <span (click)="goTo(detail.youtubeurl)" class="mt1"><img src="assets/youtube.png" alt="" class="sec_img"></span>
    </div>
  </div>
</section>
<!-- active: 1
bgcolor: "#eeaab7"
color: "#bce137"
disp_name: "Artificial Intelligence"
id: 1 -->


<section class="tab_sec">
  <button class="create_btn" (click)="openPitch(detail.id)"><mat-icon class="crt_icon">update</mat-icon> Update Pitch</button>
  <mat-tab-group [color]="'accent'" animationDuration="0ms" (selectedTabChange)="onChange($event)">
    <mat-tab label="Pitch Info">
      <mat-card class="card">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <p class="deck_p">Deck</p>
          <a class="d_btn" href="{{detail.pitchDeckFileUrl}}" target="blank">
           Download Pitch Deck</a>
        </div>
        <div class="line"></div>
        <p class="deck_p_sub">Deck Info</p>
        <p class="deck_info" [innerHTML]='detail?.deck_info'></p>
      </mat-card>
      <mat-card class="card pitch_card top">
        <h1 class="deck_p"> Pitch Deck</h1>
        <div class="line"></div>
        <form class="top">
          <p class="deck_p_sub">Title</p>
          <p class="deck_info" [innerHTML]='detail?.title'></p>
          <p class="deck_p_sub">Description</p>
          <input required placeholder="{{detail.description}}" class="input" readonly>

          <div fxLayout="row" >
            <div fxLayout="column" fxFlex="48.5">
              <p class="label">Comany Website</p>
              <input required placeholder="{{detail.cmp_website}}" class="input col" readonly>
            </div>
            <div fxLayout="column" class="col_pos" fxFlex="48.5">
              <p class="label">Company Mail</p>
              <input required placeholder="{{detail.cmp_email}}" class="input col" readonly>
            </div>
          </div>
          <div fxLayout="row" class="top">
            <div fxLayout="column" fxFlex="48.5">
              <p class="label">Company Pan</p>
              <input required placeholder="{{detail.companypan}}" class="input col" readonly>
            </div>
            <div fxLayout="column" class="col_pos" fxFlex="48.5">
              <p class="label">Company Doi</p>
              <input required placeholder="{{detail.companydoi}}" class="input col" readonly>
            </div>
          </div>
          <p class="label">How will you use funds ?</p>
          <input required placeholder="{{detail.describehowusefunds}}" class="input" readonly>
          <div class="line top"></div>
          <h6 class="deck_p_sub mt1">Faq</h6>
          <div *ngFor="let fa of  detail.faqs" class="divider">
            <div fxLayout="row" class="top">
              <div fxLayout="column" fxFlex="48.5">
                <p class="label">Created</p>
                <input required placeholder="{{fa.create_date}}" class="input " readonly >
              </div>
              <div fxLayout="column" class="col_pos" fxFlex="48.5">
                <p class="label">Faq Id</p>
                <input required placeholder="{{fa.faq_id}}" class="input " readonly >
              </div>
            </div>
            <p class="label">{{fa.faq}}</p>
            <textarea placeholder="{{fa.value}}" class="input" readonly></textarea>

            <mat-divider></mat-divider>
          </div>

          <p class="label top">Address</p>
          <input required placeholder="{{detail.hq_addr}}" class="input " readonly>
          <div fxLayout="row">
            <div fxLayout="column" fxFlex="31.5">
              <p class="label">City</p>
              <input required placeholder="{{detail.city}}" class="input " readonly>
            </div>
            <div fxLayout="column" class="col_pos" fxFlex="31.5">
              <p class="label">Country</p>
              <input required placeholder="{{detail.country}}" class="input " readonly>
            </div>
            <div fxLayout="column" class="col_posu" fxFlex="31.5">
              <p class="label">State</p>
              <input required placeholder="{{detail.state}}" class="input" readonly>
            </div>
          </div>

        </form>
      </mat-card>

    </mat-tab>

   <mat-tab label="View Campaign">
    <mat-progress-bar mode="buffer" *ngIf="isLoading" [color]="'accent'"></mat-progress-bar>
<mat-card>
  <div style="overflow-x:auto;">
    <table>
      <thead>
        <tr>
          <th>Campaign Id </th>
          <th> Target</th>
          <th> Status </th>
          <th>Start Date </th>
          <th>End Date </th>
          <th>View type</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let cdetail of cdetails" (click)="cddetaill(cdetail.id)">
          <td> {{cdetail.id}} </td>
          <td> {{cdetail.target_raise}} </td>
          <td *ngIf="(cdetail.active===1)"><button class="green ">Active</button></td>
          <td *ngIf="(cdetail.active===0)"><button class="red ">Inactive</button></td>
          <td> {{cdetail.start_date}} </td>
          <td> {{cdetail.end_date}} </td>
          <td *ngIf="(cdetail.view_type===1)"><button class="badge badgeb">Public</button></td>
          <td *ngIf="(cdetail.view_type===2)"><button class="red ">Acredited </button></td>
          <td *ngIf="(cdetail.view_type===3)"><button class="red ">Vip</button></td>
          <td *ngIf="(cdetail.view_type===4)"><button class="red ">Email</button></td>

        </tr>
      </tbody>
    </table>
  </div>
</mat-card>


    </mat-tab>
    <mat-tab label="Faq" >
      <mat-card class="card">
        <h2 class="deck_h">Frequently Asked Questions.</h2>
       <div *ngFor="let cfaq of detail.faqs">
          <p class="faq_p">{{cfaq.faq}}</p>
          <p class="faq_v">{{cfaq.value}}</p>
      </div>
    </mat-card>
    </mat-tab>
  </mat-tab-group>
</section>
</div>





<!-- active: 1
city: "Bengaluru"
cmp_email: null
cmp_logo: "https://pep.bitnamiapp.com/clientassets/icon1625170976239.jpg"
cmp_name: "TEST"
cmp_website: "winklogiq.cok"
companydoi: "2021-07-15 00:00:00"
companypan: "dsadasdasdasdasdasds"
country: 1
date_created: "2021-07-02 01:53:26"


active: 1
close_on_target: 0
curr_id: 1
date_created: "2021-07-03 17:43:37"
end_date: "2021-08-12 05:43:10"
id: 1
last_updated: "2021-07-03 17:43:37"
max_raise: 10000
min_inv: 1000
min_raise: 1000
pitch_id: 5
priority: 1
start_date: "2021-07-28 05:43:10"
status: 1
target_raise: 5000
title: "TEST CAMPAIGN"
total_raised: 13500
view_type: 1-->
