import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';
import { baseURL2 } from 'src/environments/environment';

@Component({
  selector: 'app-backers',
  templateUrl: './backers.component.html',
  styleUrls: ['./backers.component.css'],
})
export class BackersComponent implements OnInit {
  page: number = 1;
  totalRecords: String;
  test: any = 1;
  details: any;
  message: any;
  constructor(
    private _authService: AuthService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private _router: Router,
    private _httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.loader.open();
    this.docSignStat();
  }

  mine(pagination) {
    this.test = pagination;
    this.docSignStat();
  }

  docSignStat() {
    this._authService.docSignStat({ page_no: this.test }).subscribe(
      (res) => {
        console.log(res);
        this.details = res.response;
        this.snack.open(`${res.message}`, 'OK', { duration: 4000 });
        this.loader.close();
      },
      (error) => {
        this.loader.close();
        this.snack.open(`${error.message}`, 'OK', { duration: 4000 });
      }
    );
  }
}
