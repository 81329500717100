import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';

import { switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate {
  /**
   * Constructor
   */
  constructor(private _authService: AuthService, private _router: Router) {}
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  canActivate(): boolean {
    if (this._authService.loggedIn()) {
      // this._router.navigate(['/menu/dashboard']);
      this._router.navigate(['/dashboard']);
      return false;
    } else {
      return true;
    }
  }
}
