<mat-dialog-content class="mat-typography typo">
  <mat-card class="card" *ngFor="let detail of details" >
    <form [formGroup]="updateForm" class="form">
      <mat-form-field appearance="outline" class="width">
        <mat-label>Id</mat-label>
        <input matInput placeholder="Id" name="id"
          formControlName="id" [(ngModel)]="detail.id"  readonly>
      </mat-form-field>
          <mat-form-field appearance="outline" class="width" color="accent">
            <mat-label> Name</mat-label>
            <input matInput placeholder="Name" name="Name"
              formControlName="name"  required  [(ngModel)]="detail.name">
          </mat-form-field>

            <mat-form-field appearance="outline" class="width">
              <mat-label> Display Name</mat-label>
              <input matInput placeholder="Display Name" name="Name"
                formControlName="disp_name"  required [(ngModel)]="detail.disp_name">
            </mat-form-field>
            <mat-form-field appearance="outline" class="width">
              <mat-label> Color</mat-label>
              <input matInput placeholder="color" name="color"
                formControlName="color"  type="color" [(ngModel)]="detail.color">
            </mat-form-field>
            <mat-form-field appearance="outline" class="width">
              <mat-label>Background Color</mat-label>
              <input matInput placeholder="color" name="color"
                formControlName="bgcolor"  type="color" [(ngModel)]="detail.bgcolor">
            </mat-form-field>

    </form>
  </mat-card>
  </mat-dialog-content>
  <div fxLayout="row" fxLayoutAlign="end end">
    <mat-dialog-actions>
      <button mat-button mat-dialog-close  class="cbtn">Cancel</button>
      <button  [mat-dialog-close]="true" cdkFocusInitial  class="btn" (click)="update()">
      Update
      </button>
    </mat-dialog-actions>
  </div>
