<div class="px-5" *ngFor="let detail of details">
  <div class="btn_div">
    <button class="btn btn-secondary" (click)="navigate()"><span>
      </span>Back</button>
  </div>
  <div class="d-flex flex-row justify-content-between align-items-center mt-4">
    <h2 class="header_h1">User Profile</h2>
    <span class="badge text-bg-success" *ngIf="(detail.active===1)" (click)="openStatus(detail.id)"
      role="button">Active</span>
    <span class="badge text-bg-success" *ngIf="(detail.active===0)" (click)="openStatuss(detail.id)"
      role="button">Inctive</span>
  </div>


  <mat-tab-group [color]="'accent'" animationDuration="0ms" [disableRipple]="true" class="mt-4"
    (selectedTabChange)="onChange($event)">
    <mat-tab label="Profile">

      <div class="my-4">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span class="fs-4"><strong>Basic Profile</strong> </span><span class="aicon" [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </span>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openBasic(detail.id)">
              <mat-icon color="warn">edit</mat-icon>
              <span>Update</span>
            </button>
          </mat-menu>
        </div>

        <div class="grid mt-3">
          <input class="" type="text" placeholder="Id: {{ detail.id }} " class="input" readonly />
          <input class="" type="text" placeholder="Name: {{ detail.full_name }}" class="input" readonly />
          <input class="" type="text" placeholder="Email: {{ detail.email }} " class="input" readonly />
          <input class="" type="text" placeholder="Mobile: {{ detail.mobile }}" class="input" readonly />
          <input class="" type="text" placeholder="Bio: {{ detail.bio }} " class="input" readonly />
          <input class="" type="text" placeholder="Address: {{ detail.address }} " class="input" readonly />
          <input class="" type="text" placeholder="City: {{ detail.city }} " class="input" readonly />
          <input class="" type="text" placeholder="State: {{ detail.state }}" class="input" readonly />
          <input class="" type="text" placeholder="Zip: {{ detail.zip }} " class="input" readonly />
          <input class="" type="text" *ngIf="detail.user_type === 1" placeholder="User Type: Both " class="input"
            readonly />
          <input class="" type="text" *ngIf="detail.user_type === 2" placeholder="User Type: User " class="input"
            readonly />
          <input class="" type="text" *ngIf="detail.user_type === 3" placeholder="User Type: Investor " class="input"
            readonly />
          <input class="" type="text" placeholder="Date Created: {{ detail.date_created }} " class="input " readonly />
          <input class="" type="text" placeholder="Last Updated: {{ detail.last_updated }} " class="input " readonly />
          <input class="" type="text" placeholder="Facebook: {{ detail.facebook }} " class="input" readonly />
          <input class="" type="text" placeholder="Instagram: {{ detail.instagram }}" class="input" readonly />
          <input class="" type="text" placeholder="Linked In: {{ detail.linkedin }} " class="input" readonly />
        </div>
      </div>

      <hr class="my-2">

      <div class="my-4" *ngFor="let cdetail of detail.comp_profile">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span class="fs-4"><strong> Company Profile</strong></span>

          <span *ngIf="cdetail.is_verified === 1" (click)="cverify(detail.id)"
            class="d-flex flex-row align-items-center px-2 gap-1 badge text-bg-success" role="button">
            <mat-icon class="vicon">verified</mat-icon>
            <span> Verified</span>
          </span>
          <span *ngIf="cdetail.is_verified === 0"
            class="d-flex flex-row align-items-center px-2 gap-1 badge text-bg-danger" role="button"
            [matMenuTriggerFor]="menuu">
            <mat-icon class="vicon">error</mat-icon>
            <span>Not Verified</span>
          </span>

          <mat-menu #menuu="matMenu">
            <p class="p_status">Are you sure you want to change status to VERIFIED ?</p>
            <div fxLayout="row" fxLayoutAlign="end end">
              <button mat-button class="cbtn">Cancel</button>
              <button cdkFocusInitial (click)="cnverify(detail.id)" class="btn">
                Proceed
              </button>
            </div>
          </mat-menu>

          <span class="aicon" [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </span>

          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openDialog(detail.id)">
              <mat-icon color="warn">edit</mat-icon>
              <span>Update</span>
            </button>
          </mat-menu>
        </div>

        <div class="grid mt-3">
          <input type="text" placeholder="Id: {{ cdetail.user_id }} " class="input" readonly />
          <input type="text" placeholder="Name: {{ cdetail.first_name }} {{ cdetail.last_name }}" class="input "
            readonly />
          <input type="text" placeholder="Contact: {{ cdetail.mobile }} " class="input " readonly />
          <input type="text" placeholder="Company: {{ cdetail.cmp_name }} " class="input " readonly />
          <input type="text" placeholder="Company URL: {{ cdetail.website_url }} " class="input " readonly />
          <input type="text" *ngIf="cdetail.gender === 0" placeholder="Gender: Female " class="input " readonly />
          <input type="text" *ngIf="cdetail.gender === 1" placeholder="Gender: Male " class="input " readonly />
          <input type="text" placeholder="Pan: {{ cdetail.pan }} " class="input oip" readonly />
          <input type="text" placeholder="Address: {{ cdetail.city }},{{ cdetail.state }},{{
                cdetail.country
                }},{{ cdetail.zip }}." class="input oip" readonly />
        </div>
      </div>

      <hr class="my-2">

      <div class="my-4" *ngFor="let idetail of detail.investor_profile">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span class="fs-4"><strong> Investor Profile</strong></span>
          <span *ngIf="idetail.is_verified === 1" (click)="verify(detail.id)" role="button"
            class="d-flex flex-row align-items-center px-2 gap-1 badge text-bg-success">
            <mat-icon class="vicon">verified</mat-icon>
            <span>Verified</span>
          </span>
          <span *ngIf="idetail.is_verified === 0" role="button"
            class="d-flex flex-row align-items-center px-2 gap-1 badge text-bg-danger" [matMenuTriggerFor]="menuu">
            <mat-icon class="vicon">error</mat-icon>
            <span>Not Verified</span>
          </span>

          <mat-menu #menuu="matMenu">
            <p class="p_status">Are you sure you want to change status to VERIFIED ?</p>
            <div fxLayout="row" fxLayoutAlign="end end">
              <button mat-button class="cbtn">Cancel</button>
              <button cdkFocusInitial (click)="nverify(detail.id)" class="btn">
                Proceed
              </button>
            </div>
          </mat-menu>

          <span class="aicon" [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </span>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="open(detail.id)">
              <mat-icon color="warn">edit</mat-icon>
              <span>Update</span>
            </button>
          </mat-menu>
        </div>

        <div class="grid mt-4">
          <input type="text" placeholder="Id: {{ idetail.user_id }} " class="input " readonly />
          <input type="text" placeholder="Name: {{ idetail.first_name }} {{ idetail.last_name }}" class="input oip"
            readonly />
          <input type="text" placeholder="Contact: {{ idetail.mobile }} " class="input " readonly />
          <input type="text" placeholder="Company: {{ idetail.cmp_name }} " class="input " readonly />
          <input type="text" placeholder="Annual Income: &#x20B9; {{ idetail.annual_income }} " class="input oip"
            readonly />
          <input type="text" placeholder="Net worth: &#x20B9; {{ idetail.net_worth }} " class="input " readonly />
          <input type="text" placeholder="Company URL: {{ idetail.website_url }} " class="input " readonly />
          <input type="text" placeholder="Tax No.: {{ idetail.taxation_num }} " class="input " readonly />
          <input type="text" *ngIf="idetail.accredited === 0" placeholder="Accredited: False " class="input "
            readonly />
          <input type="text" *ngIf="idetail.accredited === 1" placeholder="Accredited: True " class="input " readonly />
          <input type="text" placeholder="Date Created: {{ idetail.date_created }} " class="input " readonly />
          <input type="text" placeholder="Last Updated: {{ idetail.last_updated }} " class="input " readonly />
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Pitches">
      <mat-progress-bar class="my-5" mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
      <mat-card>
        <div *ngIf="(pidetails?.length <=0 );else psec">
          <p class="tab_header">No Data Found</p>
        </div>
        <ng-template #psec class="mt-2">
          <div style="overflow-x:auto;" class="mt-4">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Pitch Id </th>
                  <th> Company Name </th>
                  <th> Created </th>
                  <th> Status </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let pidetail of pidetails" (click)="pitch(pidetail.id)">
                  <td> {{pidetail.id}} </td>
                  <td> {{pidetail.cmp_name}} </td>
                  <td> {{pidetail.date_created}} </td>
                  <td *ngIf="(pidetail.active===1)"><span class="badge text-bg-success">Active</span></td>
                  <td *ngIf="(pidetail.active===0)"><span class="badge text-bg-danger">Inactive</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-template>


      </mat-card>
    </mat-tab>

    <!-- <mat-tab label="Get All Backers">
      <mat-progress-bar mode="buffer" *ngIf="isLoading" [color]="'accent'"></mat-progress-bar>
      <mat-card class="rightcard">
        <div *ngIf="(badetails?.length <=0 );else bsec">
          <p class="tab_header">No data found.</p>
        </div>
        <ng-template #bsec>
          <span class="tab_header">All Backers</span>
          <div style="overflow-x:auto;">
            <table>
              <thead>
                <tr>
                  <th>Backer Name </th>
                  <th> City</th>
                  <th>Accredited </th>
                  <th>Total invested </th>
                  <th> TAXATION NO </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let badetail of badetails | paginate: {id: 'foo', itemsPerPage: 10, currentPage: page,totalItems: totalRecords }">
                  <td> {{badetail.first_name}}{{badetail.last_name}} </td>
                  <td> {{badetail.city}}, {{badetail.state}} </td>
                  <td *ngIf="(badetail.accredited===1)"><button class="green ">True</button>
                  </td>
                  <td *ngIf="(badetail.accredited===0)"><button class="red ">False</button></td>
                  <td> {{badetail.total_invested}} </td>
                  <td> {{badetail.taxation_num}} </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div fxLayout="row" fxLayoutAlign="end center" class="paging">
            <pagination-controls id="foo" class="pagg" (pageChange)="page =$event" (click)="mine(page)" maxSize="9"
              directionLinks="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"></pagination-controls>
          </div>
        </ng-template>


      </mat-card>
    </mat-tab> -->

    <mat-tab label="Transacations">
      <mat-progress-bar class="my-5" mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
      <mat-card class="rightcard">
        <div *ngIf="(trdetails?.length <=0 );else second">
          <p class="tab_header">No data found.</p>
        </div>
        <ng-template #second>
          <div class="mt-4">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th> Name </th>
                  <th> Title </th>
                  <th> City</th>
                  <th> Comments </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let trdetail of trdetails | paginate: {id: 'fool', itemsPerPage: 10, currentPage: page,totalItems: totalRecords }">
                  <td> {{trdetail.first_name}}{{trdetail.last_name}} </td>
                  <td> {{trdetail.campaign_title}} </td>
                  <td> {{trdetail.city}}, {{trdetail.state}} </td>
                  <td> {{trdetail.comments}} </td>

                </tr>
              </tbody>
            </table>
            <div fxLayout="row" fxLayoutAlign="end center" class="paging">
              <pagination-controls id="fool" (pageChange)="page =$event" (click)="minetr(page)" maxSize="9"
                directionLinks="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination"
                screenReaderPageLabel="page" screenReaderCurrentLabel="You're on pagee"></pagination-controls>
            </div>
          </div>
        </ng-template>




      </mat-card>
    </mat-tab>

    <mat-tab label="Bank Details">
      <mat-progress-bar mode="indeterminate" *ngIf="isLoading" class="my-5"></mat-progress-bar>
      <div *ngIf="(bankDetails?.length <=0 )">
        <p class="tab_header">No data found.</p>
        <button class="create_btn" (click)="openBankDialog(detail.id)"><mat-icon class="crt_icon">update</mat-icon>
          Create New</button>
      </div>
      <div *ngFor="let bankDetail of bankDetails">
        <span type="text" *ngIf="bankDetail.active === 0" class="badge text-btn-danger">Inactive</span>
        <span type="text" *ngIf="bankDetail.active === 1" class="badge text-btn-success">Active</span>
        <div class="grid mt-4">
          <input type="text" placeholder="User Id: {{ bankDetail.user_id }} " class="input" readonly />
          <input type="text" placeholder="Name: {{ bankDetail.name }} " class="input" readonly />
          <input type="text" placeholder="Bank Id: {{ bankDetail.bank_id }} " class="input" readonly />
          <input type="text" placeholder="Bank Name: {{ bankDetail.bank_name }}" class="input" readonly />
          <input type="text" placeholder="Account No: {{ bankDetail.acc_num }} " class="input" readonly />
          <input type="text" placeholder="IFSC: {{ bankDetail.ifsc }} " class="input" readonly />

        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>