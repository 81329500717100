<mat-dialog-content class="mat-typography typo">
  <mat-card class="card" *ngFor="let detail of details">
    <form [formGroup]="basicUpdateForm" class="form">
      <div *ngFor="let cdetail of detail.comp_profile">
        <div fxLayout="row">
          <div fxFlex="33">
            <mat-form-field appearance="outline" class="width">
              <mat-label>Id</mat-label>
              <input matInput placeholder="Name" name="id" formControlName="id" [(ngModel)]="detail.id" readonly>
            </mat-form-field>
          </div>
          <div fxFlex="33">
            <mat-form-field appearance="outline" class="width">
              <mat-label>First Name</mat-label>
              <input matInput placeholder="Name" name="Name" formControlName="first_name"
                [(ngModel)]="cdetail.first_name" required>
            </mat-form-field>
          </div>
          <div fxFlex="33">
            <mat-form-field appearance="outline" class="width">
              <mat-label>Last Name</mat-label>
              <input matInput placeholder="Name" name="lname" formControlName="last_name" required
                [(ngModel)]="cdetail.last_name">
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row">
          <div fxFlex="50">
            <mat-form-field appearance="outline" class="width">
              <mat-label> mobile</mat-label>
              <input matInput placeholder="mobile" name="mobile" formControlName="mobile" required
                [(ngModel)]="cdetail.mobile">
            </mat-form-field>
          </div>
          <div fxFlex="50">
            <mat-form-field appearance="outline" class="width">
              <mat-label>gender </mat-label>
              <input matInput placeholder="gender" name="gender" formControlName="gender" required
                [(ngModel)]="cdetail.gender">
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row">
          <div fxFlex="50">
            <mat-form-field appearance="outline" class="width">
              <mat-label> city</mat-label>
              <input matInput placeholder="mobile" name="city" formControlName="city" required
                [(ngModel)]="cdetail.city">
            </mat-form-field>
          </div>
          <div fxFlex="50">
            <mat-form-field appearance="outline" class="width">
              <mat-label>state </mat-label>
              <input matInput placeholder="gender" name="state" formControlName="state" required
                [(ngModel)]="cdetail.state">
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row">
          <div fxFlex="50">
            <mat-form-field appearance="outline" class="width">
              <mat-label>Zip </mat-label>
              <input matInput placeholder="zip" name="zip" formControlName="zip" required [(ngModel)]="cdetail.zip">
            </mat-form-field>
          </div>
          <div fxFlex="50">
            <mat-form-field appearance="outline" class="width">
              <mat-label>Company Name </mat-label>
              <input matInput placeholder="company name" name="cmp_name" formControlName="cmp_name" required
                [(ngModel)]="cdetail.cmp_name">
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row">
          <mat-form-field appearance="outline" class="width">
            <mat-label>Company URL </mat-label>
            <input matInput placeholder="company Url" name="website_url" formControlName="website_url" required
              [(ngModel)]="cdetail.website_url">
          </mat-form-field>
        </div>
      </div>

    </form>

  </mat-card>
</mat-dialog-content>

<div fxLayout="row" fxLayoutAlign="end end">
  <mat-dialog-actions>
    <button mat-button mat-dialog-close class="cbtn">Cancel</button>
    <button [mat-dialog-close]="true" cdkFocusInitial class="btn" (click)="update()">
      Update
    </button>
  </mat-dialog-actions>
</div>