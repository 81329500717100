import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/Auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';
class ImageSnippet {
  constructor(public src: string, public file: File) {}
}
@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.css'],
})
export class BasicInfoComponent implements OnInit {
  basicUpdateForm: FormGroup;
  idd: number;
  message: any;
  details: any;
  file: File = null; // Variable to store file
  imageSrc: string;
  isVisible: boolean = true;

  constructor(
    private _authService: AuthService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.userDetails();
    this.basicUpdateForm = this._formBuilder.group({
      id: ['', [Validators.required]],
      full_name: ['', [Validators.required]],
      profile_img: ['', [Validators.required]],
      cImage: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip: ['', [Validators.required]],
      bio: ['', [Validators.required]],
      facebook: ['', [Validators.required]],
      instagram: ['', [Validators.required]],
      linkedin: ['', [Validators.required]],
      address: ['', [Validators.required]],
    });
  }

  onChange(event) {
    this.file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = () => {
      this.imageSrc = reader.result as string;
    };
    this.isVisible = false;
  }

  onUpload() {
    this.loader.open();
    this._authService.uploadImage(this.file).subscribe(
      (res) => {
        this.basicUpdateForm.get('profile_img').setValue(res.response);
        this.message = res.message;
        this.loader.close();
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      },
      (err) => {
        this.loader.close();
        this.message = err.error.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }
  private userDetails() {
    this.loader.open();
    this.idd = this._authService.sid;
    this._authService.userDetailsById({ id: this.idd }).subscribe(
      (res) => {
        this.loader.close();
        this.message = res.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        this.details = res.response;
      },
      (err) => {
        this.loader.close();
        this.message = err.error.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }

  update() {
    this._authService.updateBasicProfile(this.basicUpdateForm.value).subscribe(
      (res) => {
        this.loader.close();
        this.message = res.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        this.details = res.response;
        window.location.reload();
      },
      (err) => {
        this.loader.close();
        this.message = err.error.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }
}
