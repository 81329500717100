<div class="px-5">
  <h2 class="title">All Pitch</h2>

  <table class="table table-hover">
    <thead>
      <tr>
        <th class="col align-middle">Pitch Id </th>
        <th class="col align-middle"> Company Name </th>
        <th class="col align-middle"> Created </th>
        <th class="col align-middle"> Status </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let detail of details | paginate: {id: 'foo', itemsPerPage: 10, currentPage: page,totalItems: totalRecords }"
        (click)="pitch(detail.id)">
        <td> {{detail.id}} </td>

        <td> {{detail.cmp_name}} </td>
        <td> {{detail.date_created}} </td>
        <td *ngIf="(detail.active===1)"><span class="badge text-bg-success">Active</span>
        </td>
        <td *ngIf="(detail.active===0)"><span class="badge text-bg-danger">Inactive</span></td>
      </tr>
    </tbody>
  </table>

  <div fxLayout="row" fxLayoutAlign="end center" class="paging">
    <pagination-controls id="foo" class="pagg" (pageChange)="page =$event" (click)="mine(page)" maxSize="9"
      directionLinks="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination"
      screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"></pagination-controls>
  </div>

</div>