<!-- <div > -->
  <div *ngIf="emailConfirmed;else notVerified">

    <div class="wrapper" fxLayout="row" fxLayoutAlign="space-around center">
      <mat-card class="card">
        <div class="textt">
          <img src="assets/signin.png" alt="logo" class="img">
        </div>
        <h2 class="text">Reset Password</h2>
        <form [formGroup]="confirmForm">
          <p class="label">Your Email Id <span class="star">*</span></p>
          <mat-card class="sub_card">
            <div class="form_blk">
              <mat-icon matSuffix class="icon">email</mat-icon>
              <mat-form-field appearance="none" class="width">
                <input matInput formControlName="email" required placeholder="Enter your email id" class="input" readonly>
              </mat-form-field>
            </div>
          </mat-card>
          <mat-form-field appearance="none" class="width none">
            <input matInput formControlName="session_key" required placeholder="Enter your email id" class="input">
          </mat-form-field>
          <p class="label">Enter Password <span class="star">*</span></p>
          <mat-card class="sub_card">
            <div class="form_blk">
              <mat-icon matSuffix class="icon">lock_open</mat-icon>
              <mat-form-field appearance="none" class="width">
                <input id="pass" matInput type="password" formControlName="pass" placeholder="Password" class="input"
                  (input)="onPasswordInput()" [type]="hide ? 'password':'text'">
                <span class="material-icons k" matSuffix (click)=" hide = !hide">
                  {{hide ? 'visibility':'visibility_off'}}
                </span>

              </mat-form-field>

            </div>
          </mat-card>
          <p class="label">Confirm Password <span class="star">*</span></p>
          <mat-card class="sub_card">
            <div class="form_blk">
              <mat-icon matSuffix class="icon">lock_open</mat-icon>
              <mat-form-field appearance="none" class="width">
                <input id="password-confirm" matInput type="password" formControlName="passwordConfirm"
                  placeholder="Confirm Password" class="input" (input)="onPasswordInput()"
                  [type]="hide ? 'password':'text'">
                <span class="material-icons k" matSuffix (click)=" hide = !hide">
                  {{hide ? 'visibility':'visibility_off'}}
                </span>
                <mat-error *ngIf="confirmForm.get('passwordConfirm').hasError('required')">
                  Password confirmation is required
                </mat-error>
                <mat-error *ngIf="passwordConfirm.invalid && !passwordConfirm.hasError('required')">
                  Passwords must match
                </mat-error>
              </mat-form-field>
            </div>
          </mat-card>
        </form>
        <button class="btn" [disabled]="confirmForm.invalid" (click)="confirm()">
          <span *ngIf="!confirmForm.disabled" class="btn_span">Continue</span>
        </button>

      </mat-card>
      <mat-error *ngIf="confirmForm.get('pass').hasError('pass')">
        Please enter a valid Password
      </mat-error>
    </div>
  </div>

<ng-template #notVerified>
  <div class="wrapper" fxLayout="row" fxLayoutAlign="space-around center">
    <img src="assets/notVerified.png" alt="not" class="notV">
  </div>
  <p class="link" [routerLink]="['/forgot-password']">Try Again ? Forgot Password</p>
</ng-template>

