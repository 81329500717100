<div class="px-5 ">
    <h2 class="fs-4">Offers</h2>
    <table class="table table-hover">
        <thead>
            <tr>
                <th class="col align-middle">Id</th>
                <th class="col align-middle">Name</th>
                <th class="col align-middle">Email</th>
                <th class="col align-middle">Company</th>
                <th class="col align-middle">Offer</th>
                <th class="col align-middle">Message</th>
                <th class="col align-middle">Date</th>
            </tr>
        </thead>
        <tbody>
            <tr
                *ngFor="let detail of details  | paginate: {id: 'foo', itemsPerPage: 10, currentPage: page,totalItems: totalRecords }">
                <td>{{detail.user_id}}</td>
                <td>{{detail.name}}</td>
                <td>{{detail.email}}</td>
                <td>{{detail.cmp_name}}</td>
                <td>{{detail.offer}}</td>
                <td>{{detail.message}}</td>
                <td>{{detail.date}}</td>
            </tr>
        </tbody>
    </table>

    <!--Pagination-->
    <div fxLayout="row" fxLayoutAlign="end center" class="paging">
        <pagination-controls id="foo" class="pagg" (pageChange)="page =$event" (click)="mine(page)" maxSize="9"
            directionLinks="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"></pagination-controls>
    </div>

</div>