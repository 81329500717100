import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';
import { baseURL2 } from 'src/environments/environment';

@Component({
  selector: 'app-alluser',
  templateUrl: './alluser.component.html',
  styleUrls: ['./alluser.component.css'],
})
export class AlluserComponent implements OnInit {
  page: number = 1;
  totalRecords: String;
  test: any = 1;
  details: any;
  message: any;
  stid: any;
  text: any = '';
  constructor(
    private _authService: AuthService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private _router: Router,
    private _httpClient: HttpClient,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loader.open();
    this.getAllUser();
  }

  mine(pagination) {
    this.loader.open();
    this.test = pagination;
    this.getAllUser();
  }

  getAllUser() {
    this._authService
      .users({ page_no: this.test, search_text: this.text })
      .subscribe(
        (res) => {
          this.message = res.message;
          this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
          this.details = res.response.data;
          this.totalRecords = res.response.total_records;
          this.loader.close();
        },
        (err) => {
          this.loader.close();
          this.message = err.error.message;
          this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        }
      );
  }

  download() {
    this._httpClient
      .get(baseURL2 + '/download', { responseType: 'arraybuffer' })
      .subscribe((data) => {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'data.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  updateKYC(id) {
    this.loader.open();
    const pan = prompt("Enter the user's PAN");
    this._authService.updadateKYC({ id: id, pan: pan }).subscribe(
      (res) => {
        this.snack.open(`${res.message}`, 'OK', { duration: 4000 });
        window.location.reload();
        this.loader.close();
      },
      (err) => {
        this.loader.close();
        this.snack.open(`${err.message}`, 'OK', { duration: 4000 });
      }
    );
  }

  deleteUser(id) {
    this.loader.open();
    this._authService.deleteUser({ id: id }).subscribe(
      (res) => {
        this.snack.open(`${res.message}`, 'OK', { duration: 4000 });
        window.location.reload();
        this.loader.close();
      },
      (err) => {
        this.loader.close();
        this.snack.open(`${err.message}`, 'OK', { duration: 4000 });
      }
    );
  }

  userDetails(id) {
    this._router.navigate(['userdetails', id]);
  }
}
