<mat-dialog-content class="mat-typography typo">
  <mat-card class="card"  >
    <form [formGroup]="createForm" class="form">
      <div fxLayout="row">
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label>First Name</mat-label>
            <input matInput placeholder="Name" name="Name"
              formControlName="name"  required >
          </mat-form-field>
          </div>
          <div fxFlex="50">
            <mat-form-field appearance="outline" class="width">
              <mat-label> Mobile</mat-label>
              <input matInput placeholder="mobile" name="mobile"
                formControlName="mobile"  required>
            </mat-form-field>
            </div>

      </div>
      <div fxLayout="row">
          <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label>Email </mat-label>
            <input matInput placeholder="Email" name="Email"
              formControlName="email"  required >
          </mat-form-field>
          </div>
          <div fxFlex="50">
            <mat-form-field appearance="outline" class="width">
              <mat-label> Role</mat-label>
              <input matInput placeholder="Role" name="Role"
                formControlName="role"  required >
            </mat-form-field>
            </div>
      </div>
      <div fxLayout="row">
          <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label>Year of joining </mat-label>
            <input matInput placeholder="Year of joining" name="Year of joining"
              formControlName="yoj"  required >
          </mat-form-field>
          </div>
          <div fxFlex="50">
            <mat-form-field appearance="outline" class="width">
              <mat-label>Location </mat-label>
              <input matInput placeholder="Location" name="Location"
                formControlName="location"  required >
            </mat-form-field>
            </div>
      </div>
      <div  fxLayout="row">
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label>Facebook URL</mat-label>
            <input matInput placeholder="Facebook URL" name="Facebook URL"
              formControlName="fb_url"  required >
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label>Instagram URl</mat-label>
            <input matInput placeholder="Instagram URL" name="instagram"
              formControlName="lnk_url"  required >
          </mat-form-field>
        </div>
      </div>
      <div  fxLayout="row">
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label>Twitter URL </mat-label>
            <input matInput placeholder="Twitter Url" name="twt_url"
              formControlName="twt_url"  required >
          </mat-form-field>
        </div>

      </div>
    </form>
  </mat-card>
  </mat-dialog-content>
  <div fxLayout="row" fxLayoutAlign="end end">
    <mat-dialog-actions>
      <button mat-button mat-dialog-close  class="cbtn">Cancel</button>
      <button  [mat-dialog-close]="true" cdkFocusInitial  class="btn" (click)="create()">
          Create
      </button>
    </mat-dialog-actions>
  </div>
