<div>
    <form [formGroup]="rewardForm" *ngFor="let reward of rewardz">
        <div class="d-flex flex-row gap-2">
            <mat-form-field appearance="outline" class="width">
                <mat-label>Camp Id</mat-label>
                <input matInput formControlName="camp_id" readonly [(ngModel)]="reward.camp_id">
            </mat-form-field>

            <mat-form-field appearance="outline" class="width">
                <mat-label>Amount</mat-label>
                <input matInput formControlName="amount" [(ngModel)]="reward.min_amount">
            </mat-form-field>
        </div>

        <div class="d-flex flex-row gap-2">
            <mat-form-field appearance="outline" class="width">
                <mat-label>Reward</mat-label>
                <input matInput formControlName="reward" [(ngModel)]="reward.reward">
            </mat-form-field>

            <mat-form-field appearance="outline" class="width">
                <mat-label>TNC</mat-label>
                <input matInput formControlName="tnc" [(ngModel)]="reward.tnc">
            </mat-form-field>
        </div>

        <div class="d-flex flex-row gap-2">
            <mat-form-field appearance="outline" class="width">
                <mat-label>Limit</mat-label>
                <input matInput formControlName="lmt" [(ngModel)]="reward.lmt">
            </mat-form-field>

            <mat-form-field appearance="outline" class="width">
                <mat-label>Registered</mat-label>
                <input matInput formControlName="is_registered" [(ngModel)]="reward.is_registered">
            </mat-form-field>
        </div>

        <div class="d-flex flex-row gap-2">
            <mat-form-field appearance="outline" class="width">
                <mat-label>Instrument</mat-label>
                <mat-select formControlName="instruments" [(ngModel)]="reward.instruments">
                    <mat-option *ngFor="let inst of instruments" [value]="inst.id">{{inst.instrument}}</mat-option>
                </mat-select>
                <!-- <input matInput formControlName="instruments"> -->
            </mat-form-field>

            <mat-form-field appearance="outline" class="width">
                <mat-label>Number of Instruments</mat-label>
                <input matInput formControlName="inst_num" [(ngModel)]="reward.inst_num">
            </mat-form-field>
        </div>

        <div class="d-flex flex-row gap-2">
            <mat-form-field appearance="outline" class="width">
                <mat-label>Discount Rate</mat-label>
                <input matInput formControlName="disc_rate" [(ngModel)]="reward.disc_rate">
            </mat-form-field>

            <mat-form-field appearance="outline" class="width">
                <mat-label>Return Rate</mat-label>
                <input matInput formControlName="return_rate" [(ngModel)]="reward.return_rate">
            </mat-form-field>
        </div>

        <div class="d-flex flex-row gap-2">
            <mat-form-field appearance="outline" class="width">
                <mat-label>Return Period</mat-label>
                <mat-select formControlName="return_period" [(ngModel)]="reward.return_period">
                    <mat-option *ngFor="let ret of returns" [value]="ret.id">{{ret.retVal}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="width d-none">
                <mat-label>Reward Id</mat-label>
                <input matInput formControlName="reward_id" readonly [(ngModel)]="reward.id">
            </mat-form-field>
        </div>
    </form>
</div>

<div class="d-flex flex-row gap-2 justify-content-end">

    <button mat-dialog-close class="btn btn-dark">Cancel</button>
    <button [mat-dialog-close]="true" cdkFocusInitial class="btn btn-primary" (click)="updateReward()">
        Update
    </button>

</div>