<mat-dialog-content class="mat-typography typo">
  <mat-card class="card"  >
    <form [formGroup]="createForm" class="form">
          <mat-form-field appearance="outline" class="width">
            <mat-label> Name</mat-label>
            <input matInput placeholder="Name" name="Name"
              formControlName="name"  required >
          </mat-form-field>

            <mat-form-field appearance="outline" class="width">
              <mat-label> Display Name</mat-label>
              <input matInput placeholder="Display Name" name="Name"
                formControlName="disp_name"  required>
            </mat-form-field>
            <mat-form-field appearance="outline" class="width">
              <mat-label> Color</mat-label>
              <input matInput placeholder="color" name="color"
                formControlName="color"  type="color">
            </mat-form-field>
            <mat-form-field appearance="outline" class="width">
              <mat-label>Background Color</mat-label>
              <input matInput placeholder="background color" name="bcolor"
                formControlName="bgcolor"  type="color">
            </mat-form-field>
    </form>
  </mat-card>
  </mat-dialog-content>
  <div fxLayout="row" fxLayoutAlign="end end">
    <mat-dialog-actions>
      <button mat-button mat-dialog-close  class="cbtn">Cancel</button>
      <button  [mat-dialog-close]="true" cdkFocusInitial  class="btn" (click)="create()">
          Create
      </button>
    </mat-dialog-actions>
  </div>

