import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/Auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';

@Component({
  selector: 'app-update-basic',
  templateUrl: './update-basic.component.html',
  styleUrls: ['./update-basic.component.css'],
})
export class UpdateBasicComponent implements OnInit {
  basicUpdateForm: FormGroup;
  idd: number;
  message: any;
  details: any;
  constructor(
    private _authService: AuthService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.userDetails();
    this.basicUpdateForm = this._formBuilder.group({
      id: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip: ['', [Validators.required]],
      cmp_name: ['', [Validators.required]],
      website_url: ['', [Validators.required]],
    });
  }
  private userDetails() {
    this.loader.open();
    this.idd = this._authService.sid;
    this._authService.userDetailsById({ id: this.idd }).subscribe(
      (res) => {
        this.loader.close();
        this.message = res.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        this.details = res.response;
      },
      (err) => {
        this.loader.close();
        this.message = err.error.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }
  update() {
    this.loader.open();
    this._authService.updateCprofile(this.basicUpdateForm.value).subscribe(
      (res) => {
        this.message = res.message;
        this.loader.close();
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        window.location.reload();
      },
      (err) => {
        this.loader.close();
        this.message = err.error.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }
}
