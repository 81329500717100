<mat-toolbar class="tool">
  <mat-toolbar-row>
    <!-- <button mat-icon-button (click)="toggleSideBar()">
          <mat-icon class="icon">menu</mat-icon>
      </button> -->

    <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
      <ul fxLayout="row" fxLayoutGap="20px">
        <li><mat-icon class="n_icon" [matMenuTriggerFor]="menuu">notifications</mat-icon>
          <span class="badge_pos">{{n_num}}</span>
          <mat-menu #menuu="matMenu" backdropClass="customize">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="not_head">
              <h2 class="not_h">Notifications</h2>
              <mat-icon class="not_icon">drafts</mat-icon>
            </div>
            <div *ngFor="let detail of details" class="p1" (click)="route(detail.type,detail.ref_id)">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div><img src="assets/notification.png" alt="" class="not_img"></div>
                <div fxLayout="column">
                  <p class="not_p">{{detail.message}}</p>
                  <p class="not_p">{{detail.create_date}}</p>
                </div>
                <span *ngIf="(detail.active===0)"> <mat-icon class="inactive a_icon">circle</mat-icon></span>
                <span *ngIf="(detail.active===1)"><mat-icon class="active a_icon">circle</mat-icon></span>
              </div>
              <mat-divider></mat-divider>
            </div>
          </mat-menu>

        </li>
        <li>
          <button mat-button [matMenuTriggerFor]="menu">
            <img src="assets/dealer_reg.svg" alt="" class="login">
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openDialog()" class="menu">
              <mat-icon>directions_run</mat-icon>
              Logout
            </button>
          </mat-menu>

        </li>
      </ul>
    </div>
  </mat-toolbar-row>
</mat-toolbar>


<!-- "id": 1,
"message": "New account has been created with Email : peptest1@mailinator.com",
"type": 1,
"ref_id": 27,
"user_id": 27,
"create_date": "2021-09-01 02:49:39",
"active": 1 -->