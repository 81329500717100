import { LOCALE_ID, NgModule } from '@angular/core';
import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from '../Auth/Auth.guard';
import { AuthInterceptor } from '../Auth/auth.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SharedModule } from '../Shared/shared.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DefaultComponent } from '../AllComponents/default/default.component';
import { AllPitchComponent } from '../AllComponents/all-pitch/all-pitch.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { PichdetailsidComponent } from '../AllComponents/pichdetailsid/pichdetailsid.component';
import { AllCampaignComponent } from '../AllComponents/all-campaign/all-campaign.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CampaigndetailsidComponent } from '../AllComponents/campaigndetailsid/campaigndetailsid.component';
import { BackersComponent } from '../AllComponents/backers/backers.component';
import { PaymentComponent } from '../AllComponents/payment/payment.component';
import { AlluserComponent } from '../AllComponents/alluser/alluser.component';
import { UserdetailsbyIdComponent } from '../AllComponents/userdetailsby-id/userdetailsby-id.component';
import { AllInvestosComponent } from '../AllComponents/all-investos/all-investos.component';
import { StatusComponent } from '../AllComponents/status/status.component';
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import { CstatusComponent } from '../AllComponents/cstatus/cstatus.component';
import { PstatusComponent } from '../AllComponents/pstatus/pstatus.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { UpdateBasicComponent } from '../AllComponents/update-basic/update-basic.component';
import { MatInputModule } from '@angular/material/input';
import { UpdateInvestorComponent } from '../AllComponents/update-pitch/update-investor/update-investor.component';
import { BasicInfoComponent } from '../AllComponents/basic-info/basic-info.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AllTeamComponent } from '../AllComponents/all-team/all-team.component';
import { CreateTeamComponent } from '../AllComponents/create-team/create-team.component';
import { CategoryComponent } from '../AllComponents/category/category.component';
import { SubscriberComponent } from '../AllComponents/subscriber/subscriber.component';
import { CreateCategoryComponent } from '../AllComponents/create-category/create-category.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { UpdateCampaignComponent } from '../AllComponents/update-campaign/update-campaign.component';
import { MatNativeDateModule } from '@angular/material/core';
import { UpdatePitchComponent } from '../AllComponents/update-pitch/update-pitch.component';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { UpdateBankComponent } from '../AllComponents/update-bank/update-bank.component';
import { DeletecategoryComponent } from '../AllComponents/deletecategory/deletecategory.component';
import { UpdateCateComponent } from '../AllComponents/update-cate/update-cate.component';
import { CustomofferComponent } from '../AllComponents/customoffer/customoffer.component';
import { AllCompanyComponent } from '../AllComponents/all-company/all-company.component';
import { UpdateRewardComponent } from '../AllComponents/update-reward/update-reward.component';
import { MatSelectModule } from '@angular/material/select';
@NgModule({
  declarations: [
    DashboardComponent,
    DefaultComponent,
    AllPitchComponent,
    PichdetailsidComponent,
    AllCampaignComponent,
    CampaigndetailsidComponent,
    PaymentComponent,
    BackersComponent,
    AlluserComponent,
    UserdetailsbyIdComponent,
    AllInvestosComponent,
    StatusComponent,
    CstatusComponent,
    PstatusComponent,
    UpdateBasicComponent,
    UpdateInvestorComponent,
    BasicInfoComponent,
    AllTeamComponent,
    CreateTeamComponent,
    CategoryComponent,
    SubscriberComponent,
    CreateCategoryComponent,
    UpdateCampaignComponent,
    UpdatePitchComponent,
    UpdateBankComponent,
    DeletecategoryComponent,
    UpdateCateComponent,
    CustomofferComponent,
    AllCompanyComponent,
    UpdateRewardComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatCardModule,
    FlexLayoutModule,
    MatIconModule,
    NgxPaginationModule,
    MatDialogModule,
    MatTabsModule,
    MatDividerModule,
    MatInputModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatDatepickerModule,
    NgxMatNativeDateModule,
    MatSelectModule,
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: LOCALE_ID,
      useValue: 'en-GB',
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
  ],
  entryComponents: [UpdateCateComponent],
})
export class DashboardModule {}
