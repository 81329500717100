import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/Auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';

@Component({
  selector: 'app-update-cate',
  templateUrl: './update-cate.component.html',
  styleUrls: ['./update-cate.component.css'],
})
export class UpdateCateComponent implements OnInit {
  idd: number;
  message: any;
  details: any;
  updateForm: FormGroup;
  constructor(
    private _authService: AuthService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loader.open();
    this.idd = this._authService.sid;
    this.catDetails();
    this.updateForm = this._formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required]],
      disp_name: ['', [Validators.required]],
      color: ['', [Validators.required]],
      bgcolor: ['', [Validators.required]],
    });
  }
  catDetails() {
    this._authService.cateDetailsById({ id: this.idd }).subscribe(
      (res) => {
        this.message = res.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        this.details = res.response;
        this.loader.close();
      },
      (err) => {
        this.loader.close();
        this.message = err.error.message;
        this.loader.close();
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }
  update() {
    this.loader.open();
    this._authService.updateCate(this.updateForm.value).subscribe(
      (res) => {
        this.message = res.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        window.location.reload();
        this.loader.close();
      },
      (err) => {
        this.loader.close();
        this.message = err.error.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }
}
