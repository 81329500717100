import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
})
export class PaymentComponent implements OnInit {
  details: any;
  page: number = 1;
  totalRecords: String;
  test: any = 1;

  constructor(
    private _httpClient: HttpClient,
    public dialog: MatDialog,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private _router: Router,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loader.open();
    this.getAllPayments();
  }
  mine(pagination) {
    this.loader.open();
    this.test = pagination;
    this.getAllPayments();
  }

  getAllPayments() {
    this._authService.allPayments({ page_no: this.test }).subscribe(
      (res) => {
        console.log();
        this.details = res.response.getAllPay_res.reverse();
        this.snack.open(`${res.message}`, 'OK', { duration: 4000 });
        this.totalRecords = res.response.paymentCountRes[0].total_records;
        this.loader.close();
      },
      (error) => {
        console.log(error);
        this.loader.close();
        this.snack.open(`${error.message}`, 'OK', { duration: 4000 });
      }
    );
  }
}
