import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultComponent } from '../AllComponents/default/default.component';
import { AuthGuard } from '../Auth/Auth.guard';
import { DashboardComponent } from './dashboard.component';
import { AllPitchComponent } from './../AllComponents/all-pitch/all-pitch.component';
import { PichdetailsidComponent } from './../AllComponents/pichdetailsid/pichdetailsid.component';
import { AllCampaignComponent } from './../AllComponents/all-campaign/all-campaign.component';
import { CampaigndetailsidComponent } from './../AllComponents/campaigndetailsid/campaigndetailsid.component';
import { AlluserComponent } from '../AllComponents/alluser/alluser.component';
import { UserdetailsbyIdComponent } from '../AllComponents/userdetailsby-id/userdetailsby-id.component';
import { AllTeamComponent } from '../AllComponents/all-team/all-team.component';
import { CategoryComponent } from '../AllComponents/category/category.component';
import { SubscriberComponent } from '../AllComponents/subscriber/subscriber.component';



const routes: Routes = [
// {path:'menu/dashboard',component:DashboardComponent,canActivate:[AuthGuard]},
// {path:'menu/dashboard/dashb',component:DefaultComponent,canActivate : [AuthGuard]},
// {path:'menu/dashboard/allpitch',component:AllPitchComponent,canActivate : [AuthGuard]},
// {path:'menu/dashboard/pitchdetails/:id',component:PichdetailsidComponent,canActivate : [AuthGuard]},
// {path:'menu/dashboard/allCampaign',component:AllCampaignComponent,canActivate : [AuthGuard]},
// {path:'menu/dashboard/campaigndetails/:id',component:CampaigndetailsidComponent,canActivate : [AuthGuard]},
// {path:'menu/dashboard/payment',component:AllCampaignComponent,canActivate : [AuthGuard]},
// {path:'menu/dashboard/backers',component:AllCampaignComponent,canActivate : [AuthGuard]},
// {path:'menu/dashboard/alluser',component:AlluserComponent,canActivate : [AuthGuard]},
// {path:'menu/dashboard/userdetails/:id',component:UserdetailsbyIdComponent,canActivate : [AuthGuard]},
// {path:'menu/dashboard/allTeam',component:AllTeamComponent,canActivate : [AuthGuard]},
// {path:'menu/dashboard/allTags',component:CategoryComponent,canActivate : [AuthGuard]},
// {path:'menu/dashboard/allSubscriber',component:SubscriberComponent,canActivate : [AuthGuard]},


];

@NgModule({
  imports: [RouterModule.forChild(routes)],



exports: [RouterModule]
})
export class DashboardRoutingModule { }
