import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from './signin/signin.component';
import { ForgotPassComponent } from './forgot-pass/forgot-pass.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { NoAuthGuard } from './noAuth.guard';

const routes: Routes = [
  {path:'',redirectTo:'sign-in',pathMatch:'full'},
  {path:'sign-in',component:SigninComponent,canActivate:[NoAuthGuard]},
  {path:'forgot-password',component:ForgotPassComponent,canActivate:[NoAuthGuard]},
  {path:'forgotpassv',component:ConfirmEmailComponent,canActivate:[NoAuthGuard],children:[
    {
      path:'**',
      component:ConfirmEmailComponent
      ,canActivate:[NoAuthGuard]
    }
  ]},

];

@NgModule({
imports: [RouterModule.forChild(routes)],

exports: [RouterModule]
})
export class AuthRoutingModule { }
