<div class="px-5">
  <h2>All Payments</h2>
  <table class="table table-hover">
    <thead>
      <tr>
        <th class="col align-middle">User ID</th>
        <th class="col align-middle">Pymt ID</th>
        <th class="col align-middle">Ref ID</th>
        <th class="col align-middle">Dec_trans_ref</th>
        <th class="col align-middle">Amount</th>
        <th class="col align-middle">Campaign ID</th>
        <th class="col align-middle">Reward ID</th>
        <th class="col align-middle">Init Date</th>
        <th class="col align-middle">Status</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let detail of details
            | paginate
              : {
                  id: 'foo',
                  itemsPerPage: 10,
                  currentPage: page,
                  totalItems: totalRecords
                }
        "
      >
        <td>{{ detail.user_id }}</td>
        <td>{{ detail.id }}</td>
        <td>{{ detail.ref_id }}</td>
        <td>{{ detail.dec_ref }}</td>
        <td>{{ detail.amount }}</td>
        <td>{{ detail.camp_id }}</td>
        <td>{{ detail.reward_id }}</td>
        <td>{{ detail.init_time }}</td>
        <td>{{ detail.pymt_status }}</td>
      </tr>
    </tbody>
  </table>

  <div fxLayout="row" fxLayoutAlign="end center" class="paging">
    <pagination-controls
      id="foo"
      class="pagg"
      (pageChange)="page = $event"
      (click)="mine(page)"
      maxSize="9"
      directionLinks="true"
      previousLabel="Previous"
      nextLabel="Next"
      screenReaderPaginationLabel="Pagination"
      screenReaderPageLabel="page"
      screenReaderCurrentLabel="You're on page"
    ></pagination-controls>
  </div>
</div>
