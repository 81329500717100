<mat-drawer-container class="example-container">

  <mat-drawer mode="side" [opened]="sideBarOpen" class="draw">

    <app-sidebar></app-sidebar>

  </mat-drawer>

  <mat-drawer-content class="draw2">
    <app-header (toggleSideBarForMe)="sideBarToggler()"></app-header>
    <div class="router">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>