import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseURL } from 'src/environments/environment';
import { User } from './../Model/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  sessionkey: any;
  resetpwdemail: any;
  sid: any;

  constructor(private _httpClient: HttpClient) { }
  set testid(idd: number) {
    this.sid = idd;
  }

  get testid(): number {
    return this.sid;
  }
  set access_token(tuken: string) {
    localStorage.setItem('token', tuken);
  }
  get access_token(): string {
    return localStorage.getItem('token') ?? '';
  }
  set testdata(key: string) {
    this.sessionkey = key;
  }

  get testdata(): string {
    return this.sessionkey;
  }
  set testemail(email: string) {
    this.resetpwdemail = email;
  }

  get testemail(): string {
    return this.resetpwdemail;
  }

  signIn(user: User): Observable<any> {
    return this._httpClient.post(`${baseURL}/auth/signInUserSu`, user);
  }

  forgotPass(email: string): Observable<any> {
    return this._httpClient.post(`${baseURL}/auth/forgotPassCheckSu`, email);
  }
  verify(key: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/auth/verifySessionFpassSu`, key);
  }
  update(user: User): Observable<any> {
    return this._httpClient.post(`${baseURL}/auth/updatePasswordSu`, user);
  }
  logout(): void {
    localStorage.setItem('isLoggedIn', 'false');
    localStorage.removeItem('token');
  }
  loggedIn(): boolean {
    if (localStorage.getItem('isLoggedIn') == 'true') {
      return true;
    } else {
      return false;
    }
  }
  dashstat({ }): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/dashboardstats`, {});
  }
  allPitch({ }): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getAllPitch`, {});
  }
  pitchDetailsById(pid: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getPitchById`, pid);
  }
  allTeam(pid: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getAllTeams`, pid);
  }
  campDetailsById(pid: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getCampaignDetailsById`, pid);
  }
  transRewards(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getCampaignRewards`, any);
  }
  allFaq(pid: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getAllFaq`, pid);
  }
  users(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getAllUsers_v1 `, any);
  }
  userDetailsById(pid: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getUserDetailsById`, pid);
  }
  investor(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getAllInvestors`, any);
  }
  investorDetailsById(pid: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getInvDetailsById`, pid);
  }
  toggleUser(pid: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/toggleUserAccount`, pid);
  }
  toggleCampaign(pid: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/toggleCampStatus`, pid);
  }
  togglePitch(pid: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/togglePitchStatus`, pid);
  }
  campDetailsByPid(pid: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getAllCampaignByPitch`, pid);
  }
  updateBasicProfile(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/updateBasicProfile`, any);
  }
  updateCprofile(user: User): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/updateCmpProfile`, user);
  }
  updateIprofile(user: User): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/updateInvProfile`, user);
  }
  uploadImage(file): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this._httpClient.post(`${baseURL}/su/uploadImages`, formData);
  }
  campTranscation(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getAllTransByCampId`, any);
  }
  allComments(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/findAllComments`, any);
  }
  deleteById(pid: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/deleteCommentsById`, pid);
  }
  getCampUpdate(pid: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getCampUpdate`, pid);
  }
  toggleCampaignrewards(pid: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/toggleCampaignRewards`, pid);
  }
  backers(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getAllBackers`, any);
  }
  transacationByPoc(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getAllTransBypoc`, any);
  }
  team(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getAllWebsiteTeams`, any);
  }
  createTeam(user: User): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/addWebsiteTeamMembers`, user);
  }
  toggleTeam(pid: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/toggleWebsiteTeam`, pid);
  }
  category(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getAllCategories`, any);
  }
  toggleCategory(pid: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/deleteCategory`, pid);
  }
  createCategory(user: User): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/addNewCategory`, user);
  }
  allSubscriber(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getAllSubscribeUsers`, any);
  }
  updateCamp(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/updateCampaign`, any);
  }
  updatePith(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/updatePitch`, any);
  }
  vCompProfile(pid: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/verifyCompanyProfile`, pid);
  }
  vInvProfile(pid: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/verifyInvProfile`, pid);
  }
  bankDetails(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getBackaccountDetails`, any);
  }
  updateBankData(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/updateBackaccountDetails`, any);
  }
  allNotification(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/findAllNotifications`, any);
  }
  cateDetailsById(pid: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getCategoryDetailsById`, pid);
  }
  updateCate(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/updateCategories`, any);
  }
  updateCampStatus(pid: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/updateCampaignStatus`, pid);
  }
  getOffers({ }): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getOffers`, {});
  }
  companies(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getCompanies`, any);
  }
  download({ }): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/download`, {});
  }
  updateDocSign(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/updateDocSign`, any);
  }
  getReward(rid: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getReward`, rid)
  }
  updadateReward(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/updadateReward`, any)
  }
  updadateKYC(id: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/updateKYC`, id)
  }
  deleteUser(id: any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/deleteUser`, id)
  }
  allPayments(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/getAllPayments`, any)
  }
  docSignStat(any): Observable<any> {
    return this._httpClient.post(`${baseURL}/su/docSignStat`, any)
  }
}
