<mat-dialog-content class="mat-typography typo">
  <mat-card class="card">
    <h1 class="h1">Delete Category.</h1>
    <p class="p">Are you sure to delete the category ?</p>

  </mat-card>
  </mat-dialog-content>
  <div fxLayout="row" fxLayoutAlign="end end">
    <mat-dialog-actions>
      <button mat-button mat-dialog-close  class="cbtn">Cancel</button>
      <button  [mat-dialog-close]="true" cdkFocusInitial  class="btn" (click)="toggle()">
          Proceed
      </button>
    </mat-dialog-actions>
  </div>


