<!-- completed_campaigns: 0
inprogress_campaigns: 0
pending_campaigns: 1
total_active_pitch: 1
total_campaigns: 1
total_inv_value: 0
total_investments: 0
total_pendinginv_value: 0
total_pitch: 1 -->
<div class="router" *ngFor="let detail of details">
  <h1 class="header_h1">Analytic Dashboard</h1>
  <div class="card_blk">
    <div fxLayout="row">
      <mat-card class="sub_card bg">
        <div class="first">Total Pitch</div>
        <div class="icon_div">
          <mat-icon class="div_icon">library_books</mat-icon>
        </div>
        <p class="sec">{{detail.total_pitch}}</p>
       <mat-icon class="icon">arrow_upward</mat-icon> <div class="third">{{detail.total_active_pitch}}</div><span class="span">Active</span>
      </mat-card>
      <mat-card class="sub_card">
        <div class="first">TOTAL CAMPAIGNS</div>
        <div class="icon_div second">
          <mat-icon class="div_icon">event_note</mat-icon>
        </div>
        <p class="sec">{{detail.total_campaigns}}</p>
       <mat-icon class="icon">arrow_upward</mat-icon> <div class="third">{{detail.pending_campaigns}}</div><span class="span">Pending Campaigns</span>
      </mat-card>
      <mat-card class="sub_card">
        <div class="first">Running Camp</div>
        <div class="icon_div thirdd">
          <mat-icon class="div_icon">leaderboard</mat-icon>
        </div>
        <p class="sec">{{detail.inprogress_campaigns}}</p>
       <mat-icon class="icon">arrow_upward</mat-icon> <div class="third">{{detail.completed_campaigns}}</div><span class="span">Completed Campaigns</span>
      </mat-card>
    </div>

    <!-- second row start -->
    <div fxLayout="row">
      <mat-card class="sub_card ">
        <div class="first">Investment</div>
        <div class="icon_div fourth">
          <mat-icon class="div_icon">money</mat-icon>
        </div>
        <p class="sec">{{detail.total_investments}}</p>
       <mat-icon class="icon">arrow_upward</mat-icon> <div class="third">{{detail.total_pendinginv_value}}</div><span class="span">Pending investment value</span>


      </mat-card>
      <mat-card class="sub_card row_w ">
        <div class="first">total Investment value</div>
        <div class="icon_div fifth">
          <mat-icon class="div_icon ">money</mat-icon>
        </div>
        <p class="sec">{{detail.total_inv_value}}</p>

      </mat-card>

    </div>


  </div>
</div>

<!-- completed_campaigns: 0
inprogress_campaigns: 0
pending_campaigns: 1
total_active_pitch: 1
total_campaigns: 1
total_inv_value: 0
total_investments: 0
total_pendinginv_value: 0
total_pitch: 1 -->
