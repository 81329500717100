<mat-dialog-content class="mat-typography typo">
  <mat-card class="card">

    <!-- {{currentDate | date:'yyyy-MM-dd'}} -->
    <form [formGroup]="basicCUpdateForm" class="form">
      <div *ngFor="let detail of details">
        <mat-form-field appearance="outline" class="width">
          <mat-label>Id</mat-label>
          <input matInput placeholder="Name" name="id" formControlName="id" [(ngModel)]="detail.camp_id" readonly>
        </mat-form-field>
        <mat-form-field appearance="outline" class="width">
          <mat-label> Title</mat-label>
          <input matInput placeholder="Title" name="title" formControlName="title" [(ngModel)]="detail.title" required>
        </mat-form-field>
        <mat-form-field appearance="outline" class="width">
          <mat-label>Minimum Raise </mat-label>
          <input matInput placeholder="Minimum Raise" name="min_raise" formControlName="min_raise" required
            [(ngModel)]="detail.min_raise">
        </mat-form-field>
        <mat-form-field appearance="outline" class="width">
          <mat-label> Target Raise</mat-label>
          <input matInput placeholder="Target Raise" name="max_raise" formControlName="target_raise" required
            [(ngModel)]="detail.target_raise">
        </mat-form-field>
        <mat-form-field appearance="outline" class="width">
          <mat-label> Maximum Raise</mat-label>
          <input matInput placeholder="Maximum Raise" name="max_raise" formControlName="max_raise" required
            [(ngModel)]="detail.max_raise">
        </mat-form-field>
        <mat-form-field appearance="outline" class="width">
          <mat-label>Minimum Investment </mat-label>
          <input matInput placeholder="Minimum Investment " name="min_inv" formControlName="min_inv" required
            [(ngModel)]="detail.min_inv">
        </mat-form-field>


        <mat-form-field class="width" appearance="outline" >
          <input matInput [ngxMatDatetimePicker]="pickerr" placeholder="{{detail.start_date}}"   formControlName="start_date"
           [disabled]="disabled" name="startdate"  [(ngModel)]="detail.start_date"  (dateChange)="onChangesEvent($event)"
           [min]="currentDate | date:'yyyy-MM-dd'" [max]="detail.end_date | date:'yyyy-MM-dd'">
           <mat-datepicker-toggle matSuffix [for]="pickerr"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #pickerr  [showSeconds]="showSeconds"
             [color]="color">
          </ngx-mat-datetime-picker>
       </mat-form-field>


       <!-- <ng-template #seondary>
         <input type="hidden" formControlName="start_date" [(ngModel)]="detail.start_date">
       </ng-template> -->



<div  >
  <mat-form-field class="width" appearance="outline">
    <input matInput [ngxMatDatetimePicker]="picker" placeholder="{{detail.end_date}}" formControlName="end_date"
     [disabled]="disabled" name="enddate" [(ngModel)]="detail.end_date"  (dateChange)="onChangeEvent($event)"
     [min]="detail.start_date | date:'yyyy-MM-dd'">
     <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #picker  [showSeconds]="showSeconds"
       [color]="color">
    </ngx-mat-datetime-picker>
 </mat-form-field>
</div>

       <!-- <ng-template #eseondary>
        <input type="hidden" formControlName="end_date" [(ngModel)]="detail.end_date">
      </ng-template> -->
      </div>
    </form>
  </mat-card>
</mat-dialog-content>
<div fxLayout="row" fxLayoutAlign="end end">
  <mat-dialog-actions>
    <button mat-button mat-dialog-close class="cbtn">Cancel</button>
    <button [mat-dialog-close]="true" cdkFocusInitial class="btn" (click)="update()">
      Update
    </button>
  </mat-dialog-actions>
</div>
