import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/Auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';
import { StatusComponent } from '../status/status.component';
import { UpdateBasicComponent } from './../update-basic/update-basic.component';
import { UpdateInvestorComponent } from '../update-pitch/update-investor/update-investor.component';
import { BasicInfoComponent } from './../basic-info/basic-info.component';
import { HttpClient } from '@angular/common/http';
import { baseURL2 } from 'src/environments/environment';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UpdateBankComponent } from '../update-bank/update-bank.component';

@Component({
  selector: 'app-userdetailsby-id',
  templateUrl: './userdetailsby-id.component.html',
  styleUrls: ['./userdetailsby-id.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class UserdetailsbyIdComponent implements OnInit {
  idd: number;
  message: any;
  details: any;
  pidetails: any;
  page: number = 1;
  totalRecords: String;
  test: any = 1;
  pid: number;
  badetails: any;
  bankDetails: any;
  trdetails: any;
  isLoading: boolean = false;
  constructor(
    private _authService: AuthService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    public dialog: MatDialog,
    private _httpClient: HttpClient
  ) {}

  ngOnInit(): void {
    this.loader.open();
    this.userDetails();
  }
  onChange(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    if (tab === 'Pitches') {
      this.isLoading = true;
      this.getAllPitch();
    } else if (tab === 'Get All Backers') {
      this.isLoading = true;
      this.backerDetails();
    } else if (tab === 'Transacations') {
      this.isLoading = true;
      this.transacation();
    } else if (tab === 'Bank Details') {
      this.isLoading = true;
      this.userBankDetails();
    }
  }
  mine(pagination) {
    this.loader.open();
    this.test = pagination;
    this.backerDetails();
  }
  minetr(paginationn) {
    this.loader.open();
    this.test = paginationn;
    this.transacation();
  }
  private userDetails() {
    this.idd = this._activeRoute.snapshot.params['id'];
    this._authService.userDetailsById({ id: this.idd }).subscribe(
      (res) => {
        this.message = res.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        this.details = res.response;
        this.loader.close();
      },
      (err) => {
        this.loader.close();
        this.message = err.error.message;
        this.loader.close();
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }
  openDialog(stid) {
    this._authService.sid = stid;
    const dialogRef = this.dialog.open(UpdateBasicComponent);
    dialogRef.afterClosed().subscribe((result) => {});
  }
  openBasic(stid) {
    this._authService.sid = stid;
    const dialogRef = this.dialog.open(BasicInfoComponent);
    dialogRef.afterClosed().subscribe((result) => {});
  }
  open(stid) {
    this._authService.sid = stid;
    const dialogRef = this.dialog.open(UpdateInvestorComponent);
    dialogRef.afterClosed().subscribe((result) => {});
  }
  openBankDialog(stid) {
    this._authService.sid = stid;
    const dialogRef = this.dialog.open(UpdateBankComponent);
    dialogRef.afterClosed().subscribe((result) => {});
  }

  openStatus(stid) {
    this._authService.sid = stid;
    const dialogRef = this.dialog.open(StatusComponent);
    dialogRef.afterClosed().subscribe((result) => {});
  }
  openStatuss(stid) {
    this._authService.sid = stid;
    const dialogRef = this.dialog.open(StatusComponent);
    dialogRef.afterClosed().subscribe((result) => {});
  }
  navigate() {
    // this._router.navigate(['alluser']);
    window.history.back();
  }
  getAllPitch() {
    this._httpClient
      .post<any>(`${baseURL2}/getAllPitch`, {
        page_no: this.test,
        user_id: this.idd,
      })
      .subscribe(
        (res) => {
          this.pidetails = res.response.data;
          this.message = res.message;
          this.totalRecords = res.response.total_records;
          this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
          this.loader.close();
          this.isLoading = false;
        },
        (err) => {
          this.loader.close();
          this.message = err.error.message;
          this.isLoading = false;
          this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        }
      );
  }
  pitch(id) {
    this.loader.open();
    this._router.navigate(['/menu/dashboard/pitchdetails/', id]);
    this.loader.close();
  }

  private backerDetails() {
    this.idd = this._activeRoute.snapshot.params['id'];
    this._authService
      .backers({ poc_id: this.idd, page_no: this.test })
      .subscribe(
        (res) => {
          this.message = res.message;
          this.totalRecords = res.response.total_records;
          this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
          this.badetails = res.response.data;
          this.isLoading = false;
          this.loader.close();
        },
        (err) => {
          this.loader.close();
          this.message = err.error.message;
          this.isLoading = false;
          this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        }
      );
  }
  private transacation() {
    this.idd = this._activeRoute.snapshot.params['id'];
    this._authService
      .transacationByPoc({ poc_id: this.idd, page_no: this.test })
      .subscribe(
        (res) => {
          this.totalRecords = res.response.total_records;
          this.message = res.message;
          this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
          this.trdetails = res.response.data;
          this.isLoading = false;
          this.loader.close();
        },
        (err) => {
          this.loader.close();
          this.message = err.error.message;
          this.isLoading = false;
          this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        }
      );
  }

  userBankDetails() {
    this.idd = this._activeRoute.snapshot.params['id'];
    this._authService.bankDetails({ id: this.idd }).subscribe(
      (res) => {
        this.isLoading = false;
        this.message = res.message;
        this.bankDetails = res.response;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      },
      (err) => {
        this.isLoading = false;
        this.message = err.error.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }
  verify(stid) {}
  nverify(stid) {
    this._authService.vInvProfile({ id: stid }).subscribe(
      (res) => {
        this.message = res.message;
        window.location.reload();
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        this.loader.close();
      },
      (err) => {
        this.loader.close();
        this.message = err.error.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }
  cnverify(stid) {
    this._authService.vCompProfile({ id: stid }).subscribe(
      (res) => {
        this.message = res.message;
        window.location.reload();
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        this.loader.close();
      },
      (err) => {
        this.loader.close();
        this.message = err.error.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }
}
