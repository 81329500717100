<div class="router">
  <mat-card class="main_card">
    <div class="header">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="header_div">
        <div class="info_div">
          <mat-icon class="info_icon">turned_in</mat-icon>
          <span class="info_span">All Team</span>
        </div>
      <!-- <div class="search_div">
        <mat-icon class="search_icon">search</mat-icon>
        <input type="text" placeholder="Search by title" #input class="search" name="text" [(ngModel)]="text"     (ngModelChange)="getAllPitch()">
      </div> -->
      </div>

    </div>
    <mat-card class="table_card">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="title">All Team</h2>
        <button class="create_btn" (click)="openDialog()">Create New</button>
      </div>
      <div style="overflow-x:auto;">
        <table>
          <thead>
            <tr>
              <th>Name </th>
              <th> MOBILE</th>
              <th> EMAIL </th>
              <th> ROLE </th>
              <th> JOINED ON </th>
              <th> LOCATION </th>
              <th> Status </th>
              <th> Action </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let detail of details | paginate: {id: 'foo', itemsPerPage: 10, currentPage: page,totalItems: totalRecords }" >
              <td> {{detail.name}} </td>
              <td> {{detail.mobile}} </td>
              <td> {{detail.email}} </td>
              <td > <button class="badge">{{detail.role}} </button>  </td>
              <td> {{detail.yoj}} </td>
              <td> {{detail.location}} </td>
              <td *ngIf="(detail.active===1)"><button class="green ">Active</button>
              </td>
              <td *ngIf="(detail.active===0)"><button class="red ">Inactive</button></td>
              <td><mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon></td>
              <mat-menu #menu="matMenu">
                <p class="p_status">Are you sure to delete the team ?</p>
                <div fxLayout="row" fxLayoutAlign="end end">
                  <button mat-button  class="cbtn">Cancel</button>
                  <button cdkFocusInitial (click)="toggle(detail.id)" class="btn">
                    Proceed
                  </button>
                </div>
              </mat-menu>
            </tr>
          </tbody>
        </table>
        <div fxLayout="row" fxLayoutAlign="end center" class="paging">
          <pagination-controls id="foo" class="pagg" (pageChange)="page =$event" (click)="mine(page)" maxSize="9"
            directionLinks="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"></pagination-controls>
        </div>
      </div>

    </mat-card>
  </mat-card>
</div>

<!-- active: 1
create_date: "2021-08-28 13:35:32"
email: "jharohit62@gmail.com"
fb_url: "test"
id: 1
lnk_url: "test"
location: "test"
mobile: "7377162612"
name: "Rohit"
role: "cofounder"
twt_url: "test"
yoj: "0000-00-00 00:00:00"



    -->

