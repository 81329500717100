<div class="px-5">
  <h1 class="fs-4">Doc Sign Status</h1>
  <table class="table table-hover">
    <thead>
      <tr>
        <th class="col align-middle">User ID</th>
        <th class="col align-middle"> Pymt ID </th>
        <th class="col align-middle"> DOC ID </th>
        <th class="col align-middle"> Init Date </th>
        <th class="col align-middle">Sign Date </th>
        <th class="col align-middle">Status </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let detail of details| paginate: {id: 'foo', itemsPerPage: 10, currentPage: page,totalItems: totalRecords }">
        <td> {{detail.user_id}} </td>
        <td> {{detail.pymt_id}} </td>
        <td> {{detail.doc_id}} </td>
        <td> {{detail.doc_init_date}} </td>
        <td> {{detail.doc_sign_date}} </td>
        <td *ngIf="detail.is_signed === 0"><span class="badge text-bg-warning">Pending</span></td>
        <td *ngIf="detail.is_signed === 1"> <span class="badge text-bg-success">Signed</span> </td>
      </tr>
    </tbody>
  </table>


  <div fxLayout="row" fxLayoutAlign="end center" class="paging">
    <pagination-controls id="foo" class="pagg" (pageChange)="page =$event" (click)="mine(page)" maxSize="9"
      directionLinks="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination"
      screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"></pagination-controls>
  </div>
</div>