import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  signInForm: FormGroup;
  token: any;
  returnUrl: string;
  message: any;
  constructor(
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _router: Router,
    private loader: AppLoaderService,
    private snack: MatSnackBar
  ) {}
  ngOnInit(): void {
    this._authService.loggedIn();
    this.signInForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      pass: ['', [Validators.required]],
    });
    this.returnUrl = '/menu/dashboard';
    this._authService.logout();
  }
  signin() {
    this.loader.open();
    console.log(this.signInForm.value);
    this._authService.signIn(this.signInForm.value).subscribe(
      (res) => {
        this.token = res.response.auth_token;
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('token', `${this.token}`);
        this.loader.close();
        this.snack.open('Successfully Loggedin!', 'OK', { duration: 3000 });
        this._router.navigate([this.returnUrl]);
      },
      (err) => {
        this.message = err.error.message;
        this.loader.close();
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }
}
