import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../Auth/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  id: string;
  sideBarOpen = true;
  constructor(private _authService: AuthService, private _router: Router) {}

  ngOnInit(): void {
    this.id = localStorage.getItem('token');
    // this.dashBoardStat();
  }

  sideBarToggler() {
    this.sideBarOpen = !this.sideBarOpen;
  }
  // private dashBoardStat() {
  //   this._authService.dashstat({}).subscribe(res => {
  //  console.log(res);

  //   }, error => {
  //   console.log(error);
  //   })
  // }
}
