<mat-dialog-content class="mat-typography typo">
  <mat-card class="card">
    <form [formGroup]="bankUpdateForm" class="form">
      <mat-form-field appearance="outline" class="width">
        <mat-label>Id</mat-label>
        <input matInput placeholder="id" name="id" formControlName="id" readonly>
      </mat-form-field>
      <mat-form-field appearance="outline" class="width">
        <mat-label>Bank Id</mat-label>
        <input matInput placeholder="Bank Id" name="bid" formControlName="bank_id" readonly>
      </mat-form-field>

      <mat-form-field appearance="outline" class="width">
        <mat-label> Name</mat-label>
        <input matInput placeholder="Acconte Holder Name" name="Name" formControlName="acct_name" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="width">
        <mat-label> Account Number</mat-label>
        <input matInput placeholder="Account Number" name="anumber" formControlName="acct_number" required>
      </mat-form-field>
      <mat-form-field appearance="outline" class="width">
        <mat-label> IFSC</mat-label>
        <input matInput placeholder="IFSC Number" name="color" formControlName="ifsc">
      </mat-form-field>
      <mat-form-field appearance="outline" class="width">
        <mat-label> Status</mat-label>
        <input matInput placeholder="Status" name="status" formControlName="status">
      </mat-form-field>

    </form>
  </mat-card>
</mat-dialog-content>
<div fxLayout="row" fxLayoutAlign="end end">
  <mat-dialog-actions>
    <button mat-button mat-dialog-close class="cbtn">Cancel</button>
    <button [mat-dialog-close]="true" cdkFocusInitial class="btn" (click)="create()">
      Create
    </button>
  </mat-dialog-actions>
</div>


<!-- acct_name,acct_number,ifsc,bank_id,id,status -->