import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/Auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';

interface Instruments {
  id: number;
  instrument: string;
}

interface Returns {
  id: number;
  retVal: string;
}

@Component({
  selector: 'app-update-reward',
  templateUrl: './update-reward.component.html',
  styleUrls: ['./update-reward.component.css'],
})
export class UpdateRewardComponent implements OnInit {
  instruments: Instruments[] = [
    { id: 1, instrument: 'CSOP with discount' },
    { id: 2, instrument: 'CSOP without discount' },
    { id: 3, instrument: 'SAFE with discount' },
    { id: 4, instrument: 'SAFE with post money' },
  ];

  returns: Returns[] = [
    { id: 1, retVal: '0-12 months' },
    { id: 2, retVal: '13-24 months' },
    { id: 3, retVal: '25-48 months' },
    { id: 4, retVal: '49 months onwards' },
  ];
  idd: number;
  id: number;
  rewardz: any;
  showAlert: boolean = false;
  message: any;
  isLoading: boolean = false;
  rewardForm: FormGroup;
  test: any = 1;

  constructor(
    private _activeRoute: ActivatedRoute,
    private _httpClient: HttpClient,
    private _authService: AuthService,
    private loader: AppLoaderService,
    private _router: Router,
    private snack: MatSnackBar,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getReward();
    this.rewardForm = this._formBuilder.group({
      reward_id: ['', [Validators.required]],
      camp_id: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      reward: ['', [Validators.required]],
      tnc: ['', [Validators.required]],
      lmt: ['', [Validators.required]],
      is_registered: ['', [Validators.required]],
      instruments: ['', [Validators.required]],
      inst_num: ['', [Validators.required]],
      disc_rate: ['', [Validators.required]],
      return_rate: ['', [Validators.required]],
      return_period: ['', [Validators.required]],
    });
  }

  rewards() {
    this._authService
      .transRewards({ camp_id: this.id, page_no: this.test })
      .subscribe(
        (res) => {
          this.rewardz = res.response;
          if (this.rewardz.length <= 0) {
            this.showAlert = true;
          }
          this.message = res.message;
          this.isLoading = false;
          this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        },
        (err) => {
          this.isLoading = false;
          this.loader.close();
          this.message = err.error.message;
          this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        }
      );
  }

  private getReward() {
    this.loader.open();
    this.idd = this._authService.sid;
    this._authService.getReward({ reward_id: this.idd }).subscribe(
      (res) => {
        this.rewardz = res.response;
        console.log(this.rewardz);
        this.isLoading = false;
        this.loader.close();
        this.snack.open(`${res.message}`, 'OK', { duration: 4000 });
      },
      (err) => {
        this.isLoading = false;
        this.loader.close();
        this.snack.open(`${err.message}`, 'OK', { duration: 4000 });
      }
    );
  }

  updateReward() {
    this.loader.open();
    this._authService.updadateReward(this.rewardForm.value).subscribe(
      (res) => {
        this.loader.close();
        this.snack.open(`${res.message}`, 'OK', { duration: 4000 });
      },
      (err) => {
        this.isLoading = false;
        this.loader.close();
        this.snack.open(`${err.message}`, 'OK', { duration: 4000 });
      }
    );
    // console.log(this.rewardForm.value)
  }
}
