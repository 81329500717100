<div class="wrapper" fxLayout="row" fxLayoutAlign="space-around center">

  <mat-card class="card">
    <div class="textt">
      <img src="assets/signin.png" alt="logo" class="img">
    </div>
    <h2 class="text">Forgot Password ?</h2>
    <p class="para">Don't worry ! It happens to best of us.</p>
    <form [formGroup]="forgotForm">
      <p class="label">Enter your Email Id <span class="star">*</span></p>
      <mat-card class="sub_card">
        <div class="form_blk">
          <mat-icon matSuffix class="icon">email</mat-icon>
          <mat-form-field appearance="none" class="width">
            <input matInput formControlName="email" required placeholder="Enter your email id" class="input">
          </mat-form-field>
        </div>
      </mat-card>
    </form>
    <button class="btn" [disabled]="forgotForm.invalid" (click)="reset($event)"> <span
        *ngIf="!forgotForm.disabled">Submit
      </span>
    </button>
    <p class="link" [routerLink]="['/sign-in']">Remember password? Login</p>
  </mat-card>

</div>
