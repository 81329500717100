<mat-dialog-content class="mat-typography typo">
  <mat-card class="card"  *ngFor="let detail of details">
    <form [formGroup]="basicUpdateForm" class="form">
      <div *ngFor="let cdetail of detail.investor_profile">
      <div fxLayout="row">
        <div fxFlex="33">
          <mat-form-field appearance="outline" class="width">
            <mat-label>Id</mat-label>
            <input matInput placeholder="Id" name="id"
              formControlName="id" [(ngModel)]="detail.id"  readonly>
          </mat-form-field>
          </div>
        <div fxFlex="33">
          <mat-form-field appearance="outline" class="width">
            <mat-label>First Name</mat-label>
            <input matInput placeholder="Name" name="Name"
              formControlName="first_name" [(ngModel)]="cdetail.first_name" required >
          </mat-form-field>
          </div>
          <div fxFlex="33">
          <mat-form-field appearance="outline" class="width">
            <mat-label>Last Name</mat-label>
            <input matInput placeholder="Last Name" name="lname"
              formControlName="last_name"  required [(ngModel)]="cdetail.last_name">
          </mat-form-field>
          </div>
      </div>
      <div fxLayout="row">
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label> mobile</mat-label>
            <input matInput placeholder="mobile" name="mobile"
              formControlName="mobile"  required [(ngModel)]="cdetail.mobile">
          </mat-form-field>
          </div>
          <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label>gender </mat-label>
            <input matInput placeholder="gender" name="gender"
              formControlName="gender"  required [(ngModel)]="cdetail.gender">
          </mat-form-field>
          </div>
      </div>
      <div fxLayout="row">
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label> city</mat-label>
            <input matInput placeholder="City" name="city"
              formControlName="city"  required [(ngModel)]="cdetail.city">
          </mat-form-field>
          </div>
          <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label>state </mat-label>
            <input matInput placeholder="state" name="state"
              formControlName="state"  required [(ngModel)]="cdetail.state">
          </mat-form-field>
          </div>
      </div>
      <div  fxLayout="row">
        <div fxFlex="50">
        <mat-form-field appearance="outline" class="width">
          <mat-label>Zip </mat-label>
          <input matInput placeholder="zip" name="zip"
            formControlName="zip"  required [(ngModel)]="cdetail.zip">
        </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label>Company Name </mat-label>
            <input matInput placeholder="company name" name="cmp_name"
              formControlName="cmp_name"  required [(ngModel)]="cdetail.cmp_name">
          </mat-form-field>
        </div>
      </div>
      <div  fxLayout="row">
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label>bio </mat-label>
            <input matInput placeholder="bio" name="bio"
              formControlName="bio"  required [(ngModel)]="cdetail.bio">
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label>Company URL </mat-label>
            <input matInput placeholder="company Url" name="website_url"
              formControlName="website_url"  required [(ngModel)]="cdetail.website_url">
          </mat-form-field>
        </div>

      </div>
      <div  fxLayout="row">
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label>taxation_num </mat-label>
            <input matInput placeholder="taxation_num" name="taxation_num"
              formControlName="taxation_num"  required [(ngModel)]="cdetail.taxation_num">
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label>Net worth</mat-label>
            <input matInput placeholder="Net worth" name="net_worth"
              formControlName="net_worth"  required [(ngModel)]="cdetail.net_worth">
          </mat-form-field>
        </div>

      </div>
      <div  fxLayout="row">
        <div fxFlex="50">
        <mat-form-field appearance="outline" class="width">
          <mat-label>Annual Income</mat-label>
          <input matInput placeholder="Annual Income" name="annual_income"
            formControlName="annual_income"  required [(ngModel)]="cdetail.annual_income">
        </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label>Accredited</mat-label>
            <input matInput placeholder="Accredited" name="accredited"
              formControlName="accredited"  required [(ngModel)]="cdetail.accredited">
          </mat-form-field>
          </div>
      </div>
    </div>

    </form>

  </mat-card>
  </mat-dialog-content>
  <div fxLayout="row" fxLayoutAlign="end end">
    <mat-dialog-actions>
      <button mat-button mat-dialog-close  class="cbtn">Cancel</button>
      <button  [mat-dialog-close]="true" cdkFocusInitial  class="btn" (click)="update()">
          Update
      </button>
    </mat-dialog-actions>
  </div>
<!--
  accredited: 1
  address: null
  annual_income: 20000000
  bio: "jhghjdf jsdfhb hdfbk hbn"
  city: "Kanpur"
  cmp_name: null
  country: "1"
  create_date: "2021-08-22 10:11:25"
  dob: "1993-05-13 00:00:00"
  first_name: "Ashish"
  gender: 1
  last_name: "Pathak"
  last_updated: "2021-08-22 15:41:25"
  mobile: "8872806269"
  net_worth: 89000000
  profile_img: null
  state: null
  taxation_num: "CRQPP2458C"
  user_id: 10
  website_url: "www.bbejfdjdf.com"
  zip: 208020 -->
