import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';

import { AuthService } from 'src/app/Auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';

@Component({
  selector: 'app-update-campaign',
  templateUrl: './update-campaign.component.html',
  styleUrls: ['./update-campaign.component.css'],
})
export class UpdateCampaignComponent implements OnInit {
  basicCUpdateForm: FormGroup;
  id: number;
  details: any;
  message: any;
  edate: any;
  e_date: any;
  sdate: any;
  s_date: any;
  currentDate = new Date();
  dateVisible: boolean = false;
  public showSeconds = true;
  public disabled = false;
  public color: ThemePalette = 'primary';
  date: any;
  minDate: moment.Moment;
  constructor(
    private _authService: AuthService,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loader.open();
    this.id = this._authService.sid;
    this.campDetails();
    this.basicCUpdateForm = this._formBuilder.group({
      id: [this.id, [Validators.required]],
      title: ['', [Validators.required]],
      min_raise: ['', [Validators.required]],
      max_raise: ['', [Validators.required]],
      target_raise: ['', [Validators.required]],
      min_inv: ['', [Validators.required]],
      start_date: ['', [Validators.required]],
      end_date: ['', [Validators.required]],
    });
  }

  campDetails() {
    this._authService.campDetailsById({ camp_id: this.id }).subscribe(
      (res) => {
        this.message = res.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        this.details = res.response.data;
        this.loader.close();
      },
      (err) => {
        this.loader.close();
        this.message = err.error.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }
  onChangeEvent(event) {
    this.edate = event.value;
    this.e_date = new Date(
      this.edate.getTime() - this.edate.getTimezoneOffset() * 60000
    ).toJSON();
    this.basicCUpdateForm.get('end_date').setValue(this.e_date);
  }
  onChangesEvent(event) {
    this.sdate = event.value;
    this.s_date = new Date(
      this.sdate.getTime() - this.sdate.getTimezoneOffset() * 60000
    ).toJSON();

    this.basicCUpdateForm.get('start_date').setValue(this.s_date);
  }

  update() {
    this._authService.updateCamp(this.basicCUpdateForm.value).subscribe(
      (res) => {
        this.message = res.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        window.location.reload();
        this.loader.close();
      },
      (err) => {
        this.loader.close();
        this.message = err.error.message;
        window.location.reload();
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }
}
