import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-forgot-pass',
  templateUrl: './forgot-pass.component.html',
  styleUrls: ['./forgot-pass.component.css']
})
export class ForgotPassComponent implements OnInit {
  forgotForm:FormGroup;
  message: any;
  constructor(private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _router: Router,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private _activeRoute: ActivatedRoute,
    ) { }

  ngOnInit(): void {
    this.forgotForm = this._formBuilder.group({
      email:['', [Validators.required, Validators.email]],
  });
  }

  reset(key){
    this.loader.open();
    this._authService.forgotPass(this.forgotForm.value).subscribe(res=>{
      this.message=res.message;
      this.loader.close();
      this.snack.open(`${this.message}`, 'OK', { duration:4000 });
      this._router.navigate(['/sign-in']);
    },
    err=>{
      this.message=err.error.message;
      this.loader.close();
    this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
    })
  }
}
