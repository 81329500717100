import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/Auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';

@Component({
  selector: 'app-deletecategory',
  templateUrl: './deletecategory.component.html',
  styleUrls: ['./deletecategory.component.css'],
})
export class DeletecategoryComponent implements OnInit {
  id: number;
  message: any;
  constructor(
    private _authService: AuthService,
    private loader: AppLoaderService,
    private snack: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.id = this._authService.sid;
  }
  toggle() {
    this.loader.open();
    this._authService.toggleCategory({ id: this.id }).subscribe(
      (res) => {
        this.message = res.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        window.location.reload();
        this.loader.close();
      },
      (err) => {
        this.loader.close();
        this.message = err.error.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }
}
