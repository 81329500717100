<mat-dialog-content class="mat-typography typo">
  <mat-card class="card" *ngFor="let detail of details">
    <form [formGroup]="basicUpdateForm" class="form">
      <div fxLayout="row">
        <div fxFlex="25">
          <mat-form-field appearance="outline" class="width">
            <mat-label>Id</mat-label>
            <input matInput placeholder="Name" name="id" formControlName="id" [(ngModel)]="detail.id" readonly>
          </mat-form-field>
        </div>
        <div fxFlex="37">
          <mat-form-field appearance="outline" class="width">
            <mat-label>Full Name</mat-label>
            <input matInput placeholder="Full Name" name="Name" formControlName="full_name"
              [(ngModel)]="detail.full_name" required>
          </mat-form-field>
        </div>
        <div fxFlex="37">
          <mat-form-field appearance="outline" class="width">
            <mat-label> Mobile</mat-label>
            <input matInput placeholder="Mobile" name="mobile" formControlName="mobile" required
              [(ngModel)]="detail.mobile">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label>Bio </mat-label>
            <input matInput placeholder="Bio" name="Bio" formControlName="bio" required [(ngModel)]="detail.bio">
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label> Address</mat-label>
            <input matInput placeholder="Address" name="Address" formControlName="address" required
              [(ngModel)]="detail.address">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label> City</mat-label>
            <input matInput placeholder="City" name="city" formControlName="city" required [(ngModel)]="detail.city">
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label>State </mat-label>
            <input matInput placeholder="State" name="state" formControlName="state" required
              [(ngModel)]="detail.state">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label>Zip </mat-label>
            <input matInput placeholder="zip" name="zip" formControlName="zip" required [(ngModel)]="detail.zip">
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label>Facebook </mat-label>
            <input matInput placeholder="Facebook" name="Facebook" formControlName="facebook" required
              [(ngModel)]="detail.facebook">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label>Instagram </mat-label>
            <input matInput placeholder="Instagram" name="Instagram" formControlName="instagram" required
              [(ngModel)]="detail.instagram">
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-form-field appearance="outline" class="width">
            <mat-label>Linkedin </mat-label>
            <input matInput placeholder="Linkedin" name="Linkedin" formControlName="linkedin" required
              [(ngModel)]="detail.linkedin">
          </mat-form-field>
        </div>
      </div>


      <div fxLayout="row">
        <img src="{{detail.profile_img}}" alt="" width="100" *ngIf="isVisible">
      </div>
      <div>
        <img [src]="imageSrc" width="100">
        <input type="hidden" formControlName="profile_img" [(ngModel)]="detail.profile_img" width="100">
        <input type="file" (change)="onChange($event)" formControlName="cImage">
        <button (click)="onUpload()" class="btn ">
          Upload
        </button>
      </div>

    </form>
  </mat-card>
</mat-dialog-content>
<div fxLayout="row" fxLayoutAlign="end end">
  <mat-dialog-actions>
    <button mat-button mat-dialog-close class="cbtn">Cancel</button>
    <button [mat-dialog-close]="true" cdkFocusInitial class="btn" (click)="update()">
      Proceed
    </button>
  </mat-dialog-actions>
</div>