<div class="px-5">
    <h1 class="fs-4">All Company Profiles</h1>
    <table class="table table-hover">
        <thead>
            <tr>
                <th class="">Id</th>
                <th class="">Name</th>
                <th class="">Email</th>
                <th class="">PAN</th>
                <th class="">Verified</th>
                <th class=""></th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="
                let detail of details
                  | paginate
                    : {
                        id: 'foo',
                        itemsPerPage: 10,
                        currentPage: page,
                        totalItems: totalRecords
                      }
              ">

                <td class="">{{ detail.user_id}}</td>
                <td class="">{{ detail.cmp_name }}</td>
                <td class="">{{ detail.email }}</td>
                <td class="">{{ detail.pan }}</td>
                <td>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                            [checked]="detail.is_verified ===1?true:false" [matMenuTriggerFor]="menuu"
                            [disabled]="detail.is_verified === 1? true:false">
                    </div>
                    <mat-menu #menuu="matMenu" class="p-3">
                        <p class="p_status">Are you sure you want to change status to VERIFIED ?</p>
                        <div fxLayout="row" fxLayoutAlign="end end">
                            <button mat-button class="btn btn-dark">Cancel</button>
                            <button cdkFocusInitial (click)="verifyAcc(detail.user_id)" class="btn btn-success">
                                Proceed
                            </button>
                        </div>
                    </mat-menu>
                </td>

                <td class="" (click)="userDetails(detail.user_id)">View</td>
            </tr>

        </tbody>
    </table>

    <!--Pagination-->
    <div fxLayout="row" fxLayoutAlign="end center" class="paging">
        <pagination-controls id="foo" class="pagg" (pageChange)="page = $event" (click)="mine(page)" maxSize="9"
            directionLinks="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"></pagination-controls>
    </div>

</div>