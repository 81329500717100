import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { SigninComponent } from './signin/signin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ForgotPassComponent } from './forgot-pass/forgot-pass.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { NoAuthGuard } from './noAuth.guard';
import {MatSnackBarModule} from '@angular/material/snack-bar';



@NgModule({
  declarations: [
    SigninComponent,
    ForgotPassComponent,
    ConfirmEmailComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
     ReactiveFormsModule,
     MatButtonModule,
     MatFormFieldModule,
     MatIconModule,
     MatInputModule,
     MatCardModule,
     HttpClientModule,
     FlexLayoutModule,
     MatSnackBarModule


  ],
  providers: [
    NoAuthGuard
   ],
})
export class AuthModule { }
