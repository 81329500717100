<mat-dialog-content class="mat-typography typo">
  <mat-card class="card">
    <span><mat-icon class="icon">help</mat-icon></span>
    <h1 class="h1">Logout</h1>
    <p class="p">Are you sure to logout from the application ?</p>

  </mat-card>
  </mat-dialog-content>
  <div fxLayout="row" fxLayoutAlign="end end">
    <mat-dialog-actions>
      <button mat-button mat-dialog-close  class="cbtn">Cancel</button>
      <button  [mat-dialog-close]="true" cdkFocusInitial (click)="logout()" class="btn">
          Proceed
      </button>
    </mat-dialog-actions>
  </div>


