import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/Auth/auth.service';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';
import { baseURL2 } from 'src/environments/environment';
import { PstatusComponent } from '../pstatus/pstatus.component';
import { UpdatePitchComponent } from '../update-pitch/update-pitch.component';

@Component({
  selector: 'app-pichdetailsid',
  templateUrl: './pichdetailsid.component.html',
  styleUrls: ['./pichdetailsid.component.css'],
})
export class PichdetailsidComponent implements OnInit {
  id: number;
  details: any;
  message: any;
  faqs: any;
  isDisplay1 = true;
  isDisplay2: boolean;
  cdetails: any;
  isLoading: boolean = false;
  constructor(
    private _activeRoute: ActivatedRoute,
    private _httpClient: HttpClient,
    private _authService: AuthService,
    private loader: AppLoaderService,
    public dialog: MatDialog,
    private _router: Router,
    private snack: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.loader.open();
    this.pitchDetails();
  }
  onChange(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    if (tab === 'View Campaign') {
      this.isLoading = true;
      this.campDetails();
    }
  }

  pitchDetails() {
    this.id = this._activeRoute.snapshot.params['id'];
    this._authService.pitchDetailsById({ pitch_id: this.id }).subscribe(
      (res) => {
        console.log(res);
        this.message = res.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        this.details = res.response.data;
        this.loader.close();
      },
      (err) => {
        this.loader.close();
        this.message = err.error.message;
        this.loader.close();
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }
  campDetails() {
    this.id = this._activeRoute.snapshot.params['id'];
    this._authService.campDetailsByPid({ pitch_id: this.id }).subscribe(
      (res) => {
        this.message = res.message;
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
        this.cdetails = res.response.data;
        this.loader.close();
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.loader.close();
        this.message = err.error.message;
        this.loader.close();
        this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      }
    );
  }
  cddetaill(idd: number) {
    this.loader.open();
    this._router.navigate(['/menu/dashboard/campaigndetails/', idd]);
    this.loader.close();
  }
  navigate() {
    this._router.navigate(['allpitch']);
  }
  openDialog(stid) {
    this._authService.sid = stid;
    const dialogRef = this.dialog.open(PstatusComponent);
    dialogRef.afterClosed().subscribe((result) => {});
  }
  openDialogg(stid) {
    this._authService.sid = stid;
    const dialogRef = this.dialog.open(PstatusComponent);
    dialogRef.afterClosed().subscribe((result) => {});
  }
  openPitch(stid) {
    this._authService.sid = stid;
    const dialogRef = this.dialog.open(UpdatePitchComponent);
    dialogRef.afterClosed().subscribe((result) => {});
  }
  goTo(nav) {
    window.open(`https://${nav}`, '_blank');
  }
}
