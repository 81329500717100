import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Passwordvalidatormatch } from 'src/app/Model/passwordvalidatormatch';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppLoaderService } from 'src/app/Shared/app-loader.service';


@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.css']
})
export class ConfirmEmailComponent implements OnInit {
  hide = true;
  confirmForm: FormGroup;
  emailConfirmed: boolean = false;
  urlParams: any;
  passEmail: any;
  message: any;
  key:string='';
  fUrlParams: any;
  constructor(
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _router: Router,
    private loader: AppLoaderService,
    private snack: MatSnackBar,
    private _activeRoute: ActivatedRoute,) {

    }

  ngOnInit(): void {
    this.loader.open();
    this._activeRoute.queryParams.subscribe((qp) => {
      this.loader.close();
      this.key=this._router.url;
      this.urlParams=this.key.toString().substring(13)+'==';
      this.fUrlParams=this.urlParams.toString().replace(/%2B/g, "+");
      this.confirmEmail();
    });
    this.confirmForm = this._formBuilder.group({
      email: [this.passEmail, Validators.required],
      session_key: [this.fUrlParams, Validators.required],
      pass: ['', [Validators.required]],
      passwordConfirm: ['', Validators.required]
    }, { validator: Passwordvalidatormatch })
  }
  get pass() { return this.confirmForm.get('pass'); }
  get passwordConfirm() { return this.confirmForm.get('passwordConfirm'); }
  onPasswordInput() {
    if (this.confirmForm.hasError('passwordMismatch'))
      this.passwordConfirm.setErrors([{ 'passwordMismatch': true }]);
    else
      this.passwordConfirm.setErrors(null);
  }
  confirmEmail() {
    this.loader.open();
    this._authService.verify({session_key:this.fUrlParams}).subscribe(res => {
      this.loader.close();
      this.message = res.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      this.emailConfirmed = true;
      console.log(res.response.email);
      this.confirmForm.get('email').setValue(res.response.email);
    }, err => {
      this.message = err.message;
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
      this.loader.close();
      this.emailConfirmed = false;
    })
  }
  confirm() {
    console.log(this.confirmForm.value);
    this.loader.open();
    this._authService.update(this.confirmForm.value).subscribe(res => {
      this.message = res.message;
      this.loader.close();
      this._router.navigate(['sign-in']);
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
    }, err => {
      this.message = err.error.message;
      this.loader.close();
      this.snack.open(`${this.message}`, 'OK', { duration: 4000 });
    });
  }

}

