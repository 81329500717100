<div class="router">
  <mat-card class="main_card">
    <div class="header">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="header_div">
        <div class="info_div">
          <mat-icon class="info_icon">turned_in</mat-icon>
          <span class="info_span">All Category</span>
        </div>
      <!-- <div class="search_div">
        <mat-icon class="search_icon">search</mat-icon>
        <input type="text" placeholder="Search by title" #input class="search" name="text" [(ngModel)]="text"     (ngModelChange)="getAllPitch()">
      </div> -->
      </div>

    </div>
    <mat-card class="table_card">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="title">All Category</h2>
        <button class="create_btn" (click)="openDialog()">Create New</button>
      </div>
      <div style="overflow-x:auto;">
        <table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Name </th>
              <th>display Name </th>
              <th> Color </th>
              <th>Background Color </th>
              <th> Status </th>
              <th> Action </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let detail of details| paginate: {id: 'foo', itemsPerPage: 10, currentPage: page,totalItems: totalRecords }" >
              <td> {{detail.id}} </td>
              <td> {{detail.name}} </td>
              <td> {{detail.disp_name}} </td>
              <td>  <input type="color" class="input_col" value="{{detail.color}}" disabled> </td>
              <td>  <input type="color" class="input_col" value="{{detail.bgcolor}}" disabled> </td>
              <td *ngIf="(detail.active===1)"><button class="green ">Active</button></td>
              <td *ngIf="(detail.active===0)"><button class="red ">Inactive</button></td>
              <td><mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon></td>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="updateCat(detail.id)">
                  <mat-icon>edit</mat-icon>
                  <span>Update</span>
                </button>
                <button mat-menu-item (click)="openStatus(detail.id)">
                  <mat-icon color="warn">delete</mat-icon>
                  <span >
                   Delete
                  </span>
                </button>
              </mat-menu>
            </tr>
          </tbody>
        </table>
        <div fxLayout="row" fxLayoutAlign="end center" class="paging">
          <pagination-controls id="foo" class="pagg" (pageChange)="page =$event" (click)="mine(page)" maxSize="9"
            directionLinks="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"></pagination-controls>
        </div>
      </div>

    </mat-card>
  </mat-card>
</div>


<!-- "id": 1,
"name": "AI",
"disp_name": "Artificial Intelligence",
"color": "#ffffff",
"active": 1 -->
