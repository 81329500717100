<div class="px-5">
  <h2 class="title">Subscribers</h2>

  <table class="table table-hover">
    <thead>
      <tr>
        <th class="col align-middle"> Id </th>
        <th class="col align-middle">Email</th>
        <th class="col align-middle"> Subscribed</th>
        <th class="col align-middle"> Last Updated </th>
        <th class="col align-middle"> Owner</th>
        <th class="col align-middle"> Status</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let detail of details | paginate: {id: 'foo', itemsPerPage: 10, currentPage: page,totalItems: totalRecords }">
        <td> {{detail.id}} </td>
        <td> {{detail.email}} </td>
        <td> {{detail.date_created}} </td>
        <td> {{detail.last_updated}} </td>
        <td *ngIf="(detail.is_owner===true)"><span class="badge text-bg-primary"> Yes</span></td>
        <td *ngIf="(detail.is_owner===false)"><span class="badge text-bg-danger"> No</span></td>
        <td *ngIf="(detail.active===1)"> <span class="badge text-bg-success"> Active</span></td>
        <td *ngIf="(detail.active===0)"><span class="badge text-bg-warning"> Inactive</span></td>
      </tr>
    </tbody>
  </table>

  <!--Pagination-->
  <div fxLayout="row" fxLayoutAlign="end center" class="paging">
    <pagination-controls id="foo" class="pagg" (pageChange)="page =$event" (click)="mine(page)" maxSize="9"
      directionLinks="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination"
      screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page"></pagination-controls>
  </div>
</div>