import { Injectable,Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';



@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    constructor(private _authService: AuthService,)
    {
    }
    /**
     * Intercept
     *
     * @param req
     * @param next
     */

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {

      let newReq = req.clone();

      if ( this._authService.access_token)
      {
          newReq = req.clone({
              headers: req.headers.set('x-access-token', this._authService.access_token),
          });

      }
      return next.handle(newReq).pipe(
          catchError((error) => {
              if ( error instanceof HttpErrorResponse && error.status === 401 )
              {
                  this._authService.logout();
              }

              return throwError(error);
          })
      );
  }
}
